$grade-1-3-color: var(--behaviorPink);
$grade-4-6-color: var(--behaviorBlue);
$grade-7-9-color: var(--behaviorGreen);
$grade-teacher-color: var(--behaviorPurple);

.lp--modal {
  opacity: 0;
  position: absolute;
  top: 20px;
  z-index: 4;
  left: 50%;
  margin-left: -218px;
  overflow-x: hidden;
  max-height: 90%;
  height: 90%;

  &.open {
    opacity: 1;
    top: 10%;
  }

  &.disableClose {
    .lp--modalClose {
      display: none;
    }
  }
  &.hideBackground {
    ~ #panel-mask {
      opacity: 1;
    }
  }
}

#lp--teacherPanel ~ #panel-wrapper {
  z-index: 10;
}
.lp--modal.alexandria {
  margin-left: -350px;
}

.lp--modal.categoryPicker {
  z-index: 1004;
  margin-left: -350px;
}

.lp--modal.lp--authenticationModal {
  scroll-behavior: smooth;
  &.open {
    top: 5%;
  }
}

.lp--modal.categoryPicker.small {
  margin-left: -218px;
}

.lp--categoryPicker-modalMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--black_4);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.lp--categoryPicker-modal {
  width: 700px;
  max-width: 100%;
  height: 750px;
  border-radius: 5px;
  background: var(--standardBlackBackground);
  overflow: hidden;
  max-height: 100%;
  position: relative;
}

.lp--modal {
  .lp--categoryPicker-modal {
    height: 100%;
  }
}

.lp--categoryPicker-close,
.lp--curriculumModal-close {
  display: block;
  width: 15px;
  height: 15px;
  background: url('../../../public/assets/images/theme-2/icons/close-modal.svg') center center no-repeat;
  background-size: contain;
  position: absolute;
  top: 31px;
  right: 28px;
  text-indent: -9999px;
  color: var(--transparent);
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
}

.lp--curriculumModal-close {
  background: url('../../../public/assets/images/theme-2/close-black.svg') center center no-repeat;
}

.lp--categoryPicker-brand {
  display: block;
  width: 15px;
  height: 19px;
  background: url('../../../public/assets/images/theme-2/loops-icon-white.svg') center center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 25px;
  right: 25px;
  opacity: 0.5;
}

.lp--categoryPicker-modalHeader {
  width: 100%;
  height: 75px;
  padding: 29px 31px;
  font-size: 14px;
  color: var(--grey);
  position: relative;
  transform: translateX(0%);
  transition: transform 0.3s;
}
.lp--categoryPicker-subMenu {
  display: none;
  position: absolute;
  top: 0;
  right: -200%;
  bottom: 0;
  left: 100%;
  padding: 29px 31px;
}
.lp--categoryPicker-menuBack {
  display: block;
  width: 12px;
  height: 17px;
  background: url('../../../public/assets/images/theme-2/icons/back-modal.svg') center center no-repeat;
  background-size: contain;
  text-indent: -9999px;
  color: var(--transparent);
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
}

.lp--categoryPicker-grade {
  margin: 0 0 0 19px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease;

  &:before {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid var(--grey);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: border-color 0.2s ease;
  }

  &.active:before {
    background: var(--white);
  }

  &.active,
  &:hover,
  &:focus {
    color: var(--white);
    transition: color 0.2s ease;
    &:before {
      border-color: var(--white);
      transition: border-color 0.2s ease;
    }
  }

  &.lp--categoryPicker-gradeall.active:before {
    background: var(--white);
    border: 1px solid var(--white);
  }
  &.lp--categoryPicker-grade1-3.active:before {
    background: $grade-1-3-color;
    border: 1px solid $grade-1-3-color;
  }
  &.lp--categoryPicker-grade4-6.active:before {
    background: $grade-4-6-color;
    border: 1px solid $grade-4-6-color;
  }
  &.lp--categoryPicker-grade7-9.active:before {
    background: $grade-7-9-color;
    border: 1px solid $grade-7-9-color;
  }
  &.lp--categoryPicker-gradeteacher.active:before {
    background: $grade-teacher-color;
    border: 1px solid $grade-teacher-color;
  }
}

.lp--categoryPicker-modalContentWrapper {
  position: relative;
  /* height: calc(100% - 125px); */
  height: calc(100% - 75px);

  .lp--categoryPicker-modalContentWrapperCircle {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    padding-bottom: calc(2500px - 100%);
    padding-right: calc(1250px - 50%);
    padding-left: calc(1250px - 50%);
    border-radius: 100%;
    overflow: hidden;
    box-sizing: content-box;
    border: 1px solid var(--standardBlackBackground);
    background: var(--transparent);
    transform: translateX(-50%) rotate(360deg);
  }
}

.lp--categoryPicker-modalContent {
  position: relative;
  z-index: 2;
  color: var(--white);
  padding: 86px 75px 36px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: var(--behaviorPinkFront);
  border-radius: 5px;
}

.lp--categoryPicker-grade4-6 {
  .lp--categoryPicker-modalContent {
    background: $grade-4-6-color;
  }
}

.lp--categoryPicker-grade7-9 {
  .lp--categoryPicker-modalContent {
    background: $grade-7-9-color;
  }
}

.lp--categoryPicker-gradeteacher {
  .lp--categoryPicker-modalContent {
    background: var(--behaviorPurpleBack);

    .category-title,
    li {
      color: var(--white);

      &:hover:not(&.disabled),
      &:focus:not(&.disabled),
      &.active:not(&.disabled) {
        color: var(--white) !important;
        transition: color 0.2s ease;
      }
    }
  }
}

.lp--categoryPicker-categoryBlock {
  margin-bottom: 15px;
  width: 50%;

  .category-title {
    font-size: 14px;
    color: var(--black);
    font-weight: bold;
    margin-bottom: 30px;
  }
  li {
    font-size: 19px;
    margin-bottom: 20px;
    line-height: 19px;
    cursor: pointer;
    color: var(--black);
    transition: color 0.2s ease;

    &:hover:not(&.disabled),
    &:focus:not(&.disabled),
    &.active:not(&.disabled) {
      color: var(--black);
      transition: color 0.2s ease;
    }

    &.disabled {
      cursor: auto;
      opacity: 0.5;
      color: inherit;
    }
  }
}

.lp--categoryPicker-fullWidth {
  .lp--gapType {
    flex-wrap: wrap;
  }
  .lp--categoryPicker-categoryBlock {
    width: 200px;

    li {
      color: var(--white_8);

      &:hover:not(&.disabled),
      &:focus:not(&.disabled),
      &.active:not(&.disabled) {
        color: var(--white);
      }
    }
  }
  .lp--categoryPicker-category {
    width: 200px;
    font-size: 18px;
    line-height: 30px;
    color: var(--white);
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: var(--buttonGreenFront);
      transition: color 0.2s ease;
    }
  }
  .lp--categoryPicker-modalHeader {
    padding-left: 0;
  }
}

.lp--gapType {
  display: none;
}

.lp--categoryPicker-modal {
  &.small {
    width: 327px;
    margin-left: 54px;

    .lp--categoryPicker-brand {
      bottom: 15px;
      right: 12px;
    }

    .lp--categoryPicker-modalHeader {
      color: var(--white_7);
    }

    .lp--categoryPicker-grade {
      margin: 20px 0 0 0;
      padding: 0 0 0 20px;
      display: block;
      color: var(--white);
      opacity: 0.7;
      transition: opacity 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.2s ease;
      }

      &.active {
        opacity: 1;
        cursor: default;
      }

      &:before {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        border: 1px solid var(--grey);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &.lp--categoryPicker-grade:before,
      &.lp--categoryPicker-gradeall:before {
        background: var(--white);
        border: 1px solid var(--white);
      }

      &.lp--categoryPicker-grade1-3:before {
        background: $grade-1-3-color;
        border: 1px solid $grade-1-3-color;
      }

      &.lp--categoryPicker-grade4-6:before {
        background: $grade-4-6-color;
        border: 1px solid $grade-4-6-color;
      }

      &.lp--categoryPicker-grade7-9:before {
        background: $grade-7-9-color;
        border: 1px solid $grade-7-9-color;
      }

      &.lp--categoryPicker-gradeteacher:before {
        background: $grade-teacher-color;
        border: 1px solid $grade-teacher-color;
      }
    }

    .lp--categoryPicker-subMenu {
      display: flex;
      flex-direction: row;

      .lp--categoryPicker-grade {
        display: block;
        margin: 0 0 0 19px;

        &:before {
          margin-top: 1px;
        }
      }
    }

    .lp--categoryPicker-modalContentWrapper {
      overflow: hidden;
      position: relative;
      transform: translateX(100%);
      transition: transform 0.3s;
    }

    .lp--categoryPicker-modalContent {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: auto;
    }

    .lp--categoryPicker-categoryBlock {
      width: 100%;
      margin-bottom: 45px;

      &:first-child {
        margin-top: 50px;
      }

      .category-title {
        padding-left: 17px;
      }

      li {
        position: relative;
        width: 100%;
        padding-left: 17px;
        font-size: 16px;
        margin-bottom: 16px;

        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 9px;
          height: 9px;
          border: 1px solid var(--grey);
          transition:
            border 0.2s ease,
            color 0.2s ease;
          border-radius: 50%;
        }
        &:hover,
        &:focus {
          color: var(--white);
          transition: color 0.2s ease;

          &:before {
            width: 8px;
            height: 8px;
            border: 2px solid var(--white);
            transition: border 0.2s ease;
          }
        }
        &.active {
          color: var(--white);

          &:before {
            background: var(--white);
          }
        }
      }
    }

    &.lp--categoryPicker-gradeView {
      .lp--categoryPicker-modalHeader {
        color: var(--grey);
        transform: translateX(-100%);
      }
      .lp--categoryPicker-modalContentWrapper {
        display: block;
        transform: translateX(0%);
      }
    }
  }
}

.lp--authenticateModal,
.lp--shareCodeModal {
  width: 100%;
  background-color: var(--buttonGreenBack);
  border-radius: 12px;
  border: 2px solid var(--buttonGreenFront);

  @media screen and (max-width: 767px) {
    border: none;
    height: 100%;
  }

  a {
    border-bottom: 1px solid;
    font-weight: 400;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    color: var(--slateGrey);

    &:hover,
    &:focus {
      transition: color 0.2s ease-in-out;
      color: var(--white);
    }
  }

  .lp--modalClose {
    width: 16px;
    height: 16px;
    background-image: url('../../../public/assets/images/theme-2/close-white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--transparent);
    opacity: 0.5;
    position: absolute;
    right: 34px;
    top: 34px;
    transition: opacity 0.2s ease-in-out;
    border: none;

    &:hover,
    &:focus {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.8;
    }

    @media screen and (max-width: 767px) {
      top: 14px;
      right: 14px;
    }
  }

  .lp--authenticateModalBodyViewport {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;

    .lp--authenticateModalBodyWrapper {
      width: 10000px;
      max-height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      transition: transform 0.3s;
    }
  }

  .lp--authenticateModalBody {
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    padding: 45px 60px 45px 67px;
    color: var(--white);

    .lp--authenticateModalTypeSwitch {
      margin-left: auto;
    }

    h2 {
      font-size: 23px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      margin: 30px 0;
    }

    .lp--processingConsentField {
      margin-top: 15px;
    }

    .lp--formCheckboxLabel:focus,
    .lp--formRadioButtonLabel:focus {
      outline: none;
      color: var(--white);

      label {
        color: var(--white);
      }
    }

    .lp--formErrorMessage + .lp--form {
      margin-top: 36px;
    }

    .lp--authenticateSSOLogin,
    .lp--authenticatePasswordLogin {
      margin: 0px 0px 0px auto;
    }

    .lp--authenticatePasswordReminder {
      margin: 0px 0px 0px 20px;
    }

    .lp--formSubmitCircular {
      background-image: url(../../../public/assets/images/theme-2/icons/green-arrow@2x.png);
      background-size: 15px 14px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .lp--categoryPicker-embeddedHeader {
    .lp--categoryPicker-subMenu {
      position: static;
      left: 0px;
      padding: 0px 0px 0px 24px;
      display: block;
      font-size: 14px;
      margin-bottom: 40px;

      .lp--categoryPicker-grade:before {
        border-color: var(--white);
      }
    }

    h3 {
      font-size: 19px;
      margin-bottom: 18px;
      padding-left: 24px;
    }

    label {
      font-weight: normal;
    }
  }

  .lp--categoryPicker-embedded {
    .lp--categoryPicker-grade1-3.active:before,
    .lp--categoryPicker-grade4-6.active:before,
    .lp--categoryPicker-grade7-9.active:before,
    .lp--categoryPicker-gradeteacher.active:before {
      background-color: var(--white);
      border-color: var(--white);
    }
    .lp--categoryPicker-modalContentWrapper {
      height: auto;

      .lp--categoryPicker-modalContentWrapperCircle {
        position: static;
        left: 0px;
        transform: none;
        padding: 0px;
        border-radius: 0px;
        border: none;

        .lp--categoryPicker-modalContent {
          height: auto;
          overflow-y: initial;
          background: var(--transparent);
          padding: 0px;

          .lp--categoryPicker-categoryBlock {
            .category-title {
              padding-left: 24px;
              color: var(--white_4);
              font-size: 14px;
            }
            li {
              position: relative;
              padding-left: 24px;
              margin-bottom: 18px;

              &:last-child {
                margin-bottom: 30px;
              }

              &:before {
                content: ' ';
                position: absolute;
                left: 0;
                top: 2px;
                width: 15px;
                height: 15px;
                border: 1px solid var(--white);
                border-radius: 50%;
              }
              &:hover,
              &:focus {
                color: var(--white);

                &:before {
                  width: 15px;
                  height: 15px;
                  border: 2px solid var(--white);
                }
              }
              &.active {
                color: var(--white);

                &:before {
                  background: var(--white);
                }
              }
            }
          }
        }
      }
    }
  }
}

.lp--shareCodeModal {
  .lp--shareCodeSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  h2 {
    margin-left: -20px;
    text-align: center;
    position: relative;
    padding-left: 40px;

    &:before {
      background-image: url(../../../public/assets/images/theme-2/icons/toolbar/password.svg);
      width: 30px;
      background-repeat: no-repeat;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      display: inline-block;
      content: ' ';
    }
  }
}

.lp--authenticateModal .lp--authenticateModalBody p.invite-logout-text {
  margin-top: 43px;
  margin-bottom: 18px;
}

.lp--shareCodeSection {
  a {
    margin: 30px 0;
    display: block;
  }
}

.lp--inviteModal {
  .lp--authenticateModalBody {
    padding-top: 0px;
  }

  .lp--inviteModalHeader {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 45px;

    h2 {
      margin: auto 0;
    }

    .lp--userAvatar {
      width: 75px;
      height: 75px;
      background-size: cover;
      margin: 0px 27px 0px 0px;
    }
  }

  .lp--inviteModalActions {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    button {
      font-weight: bold;
      font-size: 16px;
      transition: filter 0.3s ease-in-out;
      filter: brightness(1);
      min-width: 150px;

      &:hover,
      &:focus {
        transition: filter 0.3s ease-in-out;
        filter: brightness(1.3);
      }
    }
  }

  .invitation-login-button {
    background-image: url(../../../public/assets/images/theme-2/icons/submit-arrow.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position-y: center;
    background-position-x: calc(100% - 15px);

    height: 36px;
    font-size: 16px;
    font-weight: normal;
    padding: 0 18px;
    padding-right: 40px;
    border-radius: 18px;
    border: 2px solid var(--buttonGreenFront);
    background-color: var(--black);
    color: var(--buttonGreenFront);
    transition: filter 0.3s ease-in-out;
    filter: brightness(1);
    outline: none;

    &:hover,
    &:focus {
      transition: filter 0.3s ease-in-out;
      filter: brightness(1.3);
    }
  }
}

.lp--modalActionBtnSecondary {
  background-color: var(--transparent);
  border: 1px solid var(--white);
  border-radius: 200px;
  padding: 5px 12px;
}

.lp--modalActionBtnPrimary {
  border: none;
  background-color: var(--white);
  color: var(--buttonGreenFront);
  border-radius: 200px;
  padding: 5px 12px;
}

.lp--publishLoopModal {
  width: 100%;
  padding: 22px 0 0;
  background-color: var(--standardBlackBackground);
  outline: none;
  color: var(--white_7);
  font-size: 15px;
  border-radius: 6px;
  border: 2px solid var(--menuButtonBorder);

  a,
  a:hover,
  a:focus,
  a:visited {
    text-decoration: underline;
    color: var(--white_7);
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: var(--white);
      transition: color 0.2s ease;
    }
  }

  .lp--publishLoopModalContent {
    padding: 0px 40px 0px 50px;
  }

  .lp--publishLoopTitle {
    margin-bottom: 10px;
    margin-top: 30px;
    display: none;
  }

  .lp--modalTitle {
    font-size: 30px;
    color: var(--white);
    margin: 30px 0;
  }

  .lp--publishLoopSubtitle {
    font-size: 19px;
    color: var(--white_7);
    margin-top: 22px;
    margin-bottom: 15px;

    &.lp--publishLoopSubtitleGeneralLibrary {
      font-size: 20px;
      margin-bottom: 15px;
      margin-top: 0px;
      color: var(--white);
    }
  }

  .lp--publishLoopDestinationWrapper {
    p,
    h4 {
      flex: 1;
    }

    h4 {
      margin-right: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      font-size: 15px;
      font-style: italic;
      color: var(--headerGrey);
      margin-bottom: 10px;
      text-align: left;
    }
    button {
      flex: none;
      background-color: var(--loopsGreen);
      color: var(--black);
      outline: none;
      border: 2px solid var(--transparent);
      padding: 6px 14px;
      border-radius: 100px;
      font-size: 14px;
      font-weight: bold;
      min-width: 100px;
      height: 34px;

      &:disabled {
        opacity: 0.5;
      }

      transition: border-color 0.2s ease;

      &:hover,
      &:focus {
        border-color: var(--white);
        transition: border-color 0.2s ease;
      }
    }

    &.lp--publishLoopOrganisationDestination {
      margin-bottom: 34px;
    }
  }

  .publish-info__wrapper {
    border-top: 1px solid var(--white_2);
    padding: 21px 35px;
  }

  .publish-info-toggle {
    margin: 0 15px;
    font-weight: normal;
    cursor: pointer;

    &:after {
      content: url(../../../public/assets/images/icons/arrow-down.svg);
      margin-left: 10px;
    }

    &.visible:after {
      content: url(../../../public/assets/images/icons/arrow-up.svg);
    }

    border-bottom: 1px solid var(--transparent);
    transition: border-color 0.2s ease;

    &:hover,
    &:focus {
      border-color: var(--white);
      transition: border-color 0.2s ease;
    }
  }

  .publish-loop__button-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: var(--headerGrey);
      font-style: italic;
    }
  }

  .publish-info {
    &.visible {
      transition: max-height 0.3s ease-in-out;
      height: auto;
      max-height: 600px;
    }

    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 0;

    button {
      min-width: 100px;
      height: 34px;

      border: 2px solid var(--loopsGreen);
      border-radius: 18px;
      background-color: var(--transparent);
      padding: 6px 14px;
      color: var(--loopsGreen);
      line-height: 16px;
      transition:
        color 0.2s ease-in-out 0s,
        background-color 0.2s ease-in-out 0s;
      white-space: nowrap;
      opacity: 1;

      &:hover,
      &:focus {
        transition:
          border-color 0.2s ease-in-out 0s,
          background-color 0.2s ease-in-out 0s;
        background-color: var(--transparent);
        border-color: var(--white);
      }
    }
  }

  .publish-info__inner {
    padding: 0 15px;
    margin-top: 20px;
  }

  .publish-info__profile {
    margin-top: 28px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
  }

  .publish-info__image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--hoverGrey);
    display: inline-block;
    margin-right: 15px;
  }

  .publish-info__bio-label,
  .publish-info__byline-label {
    font-size: 14px;
    font-style: italic;
    color: var(--headerGrey);
  }

  .publish-info__bio-label {
    margin-bottom: 11px;
  }

  .publish-info__bio,
  .publish-info__byline,
  .publish-info__full-name {
    font-size: 14px;
    color: var(--white);
  }

  .publish-info__bio {
    border: 2px solid var(--defaultPlaceholder);
    border-radius: 10px;
    height: 94px;
    padding: 10px 15px;
    margin-right: -15px;
    line-height: 20px;
  }

  .publish-info__button-panel {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) {
  .lp--publishLoopModal {
    width: 435px;
  }

  .lp--modal.lp--authenticationModal,
  .lp--modal.lp--payModal,
  .lp--modal.lp--shareCodeModal {
    margin-left: -337px;
  }

  .lp--authenticateModal,
  .lp--shareCodeModal {
    width: 675px;

    .lp--authenticateModalBody {
      width: 675px;
    }
  }
}
