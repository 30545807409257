@import '~react-datepicker/dist/react-datepicker.css';

.lp--teacherPanel {
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  z-index: 9;
  overflow: hidden;
  pointer-events: none;

  .panel-content img {
    max-width: 100%;
    max-height: 100%;
  }

  .pr2--listIconWrapper {
    width: 30px;
    flex: none;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 30px;
    margin-right: 3px;
    flex-shrink: 0;

    &.pr2--listIconWrapper-task,
    &.pr2--listIconWrapper-written_quiz {
      background-size: 9px;
    }

    &.pr2--listIconWrapper-group {
      background-size: 16px;
    }

    &.pr2--listIconWrapper-quiz {
      background-size: 20px;
    }
  }

  // This will need to be refactored, because this will cause a traversal of the entire
  // dom tree from the lowliest span to the highest <html>
  > div {
    flex-shrink: 0;

    &:last-child {
      margin-right: auto;
    }
  }

  h2 {
    font-size: 20px;
  }

  h1,
  h4 {
    font-size: 13px;
    padding-bottom: 10px;
    margin-bottom: 11px;
    border-bottom: 1px solid var(--white_1);
    text-transform: uppercase;
  }

  .lp--teacherPanel-createGroup {
    padding-left: 19px;
    background: url(../../../public/assets/images/theme-2/icons/add-setting-white.svg) center left no-repeat;
    background-size: 14px 14px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .lp--userAvatar {
    width: 25px;
    height: 25px;
    background: var(--white_6);
    color: var(--black);
    font-weight: bold;
    border-radius: 50%;
    margin-right: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-shrink: 0;
    position: relative;

    &.online::after {
      content: ' ';
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: var(--loopsGreen);
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .lp--commentPanel {
    background: var(--white);
    min-width: 480px;
    min-height: 100%;
    box-shadow: 4px 0px 5px -2px var(--black_2) inset;
    position: relative;
    z-index: -2;
    margin-left: -630px;
    transition: 0.3s;

    &.open {
      margin-left: 0;
    }
  }
}
.lp--teacherPanel .legend-viewer .concept-body {
  margin: 0;
}
.lp--teacherPanel-contextList > ul {
  margin-bottom: 67px;
}
.lp--teacherPanel-contextList-headers {
  display: flex;
  justify-content: space-between;
  color: var(--searchFieldPlaceholder);
  font-size: 12px;
  margin-bottom: 15px;
  padding: 0;
  margin-right: -15px;
}
.lp--teacherPanel-contextSelection {
  width: 433px;
  max-width: 100%;
  height: 100%;
  background: var(--standardBlackBackground);
  color: var(--searchFieldPlaceholder);
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
  z-index: 2;
  pointer-events: all;
  transition:
    color 0.2s ease,
    background-color 0.2s ease;

  > header {
    margin-bottom: 48px;
  }

  .lp--formButton {
    float: none;
    margin-bottom: 35px;
  }

  .lp--teacherPanel-contextListItem {
    &:hover,
    &:focus {
      color: var(--white);
      background-color: var(--white_2);
      transition:
        color 0.2s ease,
        background-color 0.2s ease;
    }
  }

  h2 {
    color: var(--white_8);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.2s;
  }

  .pr2--subNav {
    position: relative;
    margin-bottom: 40px;
    display: flex;

    li {
      margin-right: 28px;
      position: relative;
      cursor: pointer;

      a {
        font-size: 14px;
      }

      &:hover,
      &:hover a,
      &:focus,
      &:focus a {
        color: var(--white_8);
      }

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: -8px;
        height: 4px;
        width: 100%;
        background: var(--transparent);
        border-radius: 2px;
        transform: translateY(-10px);
        transition: 0.2s;
      }
    }

    li.active {
      a {
        color: var(--white);
        border-bottom: none;
        text-decoration: none;
      }

      &:after {
        background: var(--white);
        transform: translateY(0px);
      }
    }
  }
}

.lp--teacherPanel-activities {
  h1,
  h4 {
    font-size: 13px;
    padding-bottom: 10px;
    margin-bottom: 11px;
    border-bottom: 1px solid var(--white_1);
  }

  .pr2--listIconWrapper {
    width: 30px;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 30px;
    margin-right: 3px;

    &.pr2--listIconWrapper-task,
    &.pr2--listIconWrapper-written_quiz {
      background-size: 9px;
    }

    &.pr2--listIconWrapper-group {
      background-size: 16px;
    }

    &.pr2--listIconWrapper-quiz {
      background-size: 20px;
    }
  }
}

.lp--teacherPanel-loopDetails {
  margin: -17px 0 0 0;
  max-width: calc(100% - 60px);
}

.lp--teacherPanel-loopName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lp--teacherPanel-sessionDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  div {
    margin-right: 16px;
  }

  .lp--sessionCircleEditorLink {
    position: relative;
    padding-left: 17px;
    color: var(--white);
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;

      &:after {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }

    &:after {
      content: ' ';
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
      background-image: url(../../../public/assets/images/theme-2/icons/cog-white.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      position: absolute;
      left: -4px;
      top: 0;
    }
  }

  .lp--teacherPanel-sessionCircleCloneLink {
    position: relative;
    padding-left: 17px;
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;

      &:after {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }

    &:after {
      content: ' ';
      background-image: url(../../../public/assets/images/theme-2/icons/copy.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 18px;
      position: absolute;
      left: -6px;
      top: 5px;
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
    }
  }

  .lp--teacherPanel-shareLink {
    .lp--teacherPanel-loopcode {
      position: relative;
      padding-left: 17px;
      color: var(--white);
      opacity: 0.7;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      &:hover,
      &:focus {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:after {
          transition: opacity 0.3s ease-in-out;
          opacity: 1;
        }
      }

      &:after {
        content: ' ';
        background-image: url(../../../public/assets/images/theme-2/icons/toolbar/password.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 18px;
        height: 18px;
        position: absolute;
        left: -6px;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        opacity: 0.7;
      }
    }
  }
}

.lp--sessionStateMgmt {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.lp--sessionStateMgmt-states {
  flex: 3;
  height: 20px;
  border-radius: 9px;
  background: var(--white_1);
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
}

.lp--sessionStateMgmt-state {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  width: auto;
  flex: 1;
  height: 100%;
  line-height: 20px;
  vertical-align: middle;
  position: relative;
  font-size: 12px;

  &:after {
    content: ' ';
    display: block;
    transform: rotate(45deg) translateY(-50%);
    width: 18px;
    height: 18px;
    border-top: 1px solid var(--menuButtonBorder);
    border-right: 1px solid var(--menuButtonBorder);
    position: absolute;
    right: 10px;
    top: 7px;
  }

  &.lp--sessionStateMgmt-currentState,
  &.lp--sessionStateMgmt-oldState {
    background: var(--progressBarGreen);
    color: var(--black);
    border-radius: 9px;
  }

  &.lp--sessionStateMgmt-oldState:first-child {
    border-radius: 0px;
  }

  &.lp--sessionStateMgmt-oldState + &.lp--sessionStateMgmt-currentState {
    border-radius: 0px 9px 9px 0px;
  }

  &.lp--sessionStateMgmt-oldState + &.lp--sessionStateMgmt-oldState {
    border-radius: 0px;
  }

  &.lp--sessionStateMgmt-currentState:after,
  &:last-child:after {
    display: none;
  }
}

.lp--sessionStateMgmt-action {
  margin-left: 11px;
  height: 28px;
  text-align: center;
  border: 2px solid var(--white_1);
  border-radius: 12px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    color 0.2s ease,
    border-color 0.2s ease;

  &:hover,
  &:focus {
    background-color: var(--black_2);
    color: var(--white);
    border-color: var(--hoverGrey);
    transition:
      background-color 0.2s ease,
      color 0.2s ease,
      border-color 0.2s ease;
  }

  &:active {
    background-color: var(--transparent);
  }
}

.lp--teacherPanel-shareButtons {
  display: flex;
  margin: 10px 0;

  div {
    height: 32px;
    margin-right: 8px;

    a,
    iframe {
      outline: none;
      transition: outline 0.2s ease;

      &:hover,
      &:focus {
        outline: 2px solid var(--white);
        transition: outline 0.2s ease;
      }
    }
  }
}

.lp--teacherPanel-subActionRow {
  display: flex;
  justify-content: space-between;
}

.lp--teacherPanel-sessionEdit,
.lp--teacherPanel-teamsImport {
  color: var(--white);
  display: inline-block;

  a {
    position: relative;
    padding-left: 17px;
    color: var(--white);
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;

      &:after {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }

    &:after {
      content: ' ';
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
      background-image: url(../../../public/assets/images/theme-2/icons/edit-pen.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 14px;
      height: 14px;
      position: absolute;
      left: -2px;
      top: 1px;
    }
  }
}

.lp--teacherPanel-teamsImport {
  a {
    &:after {
      opacity: 1;
      background-image: url(../../../public/assets/images/theme-2/teams-logo.svg);
    }
  }
}

.lp--teacherPanel-contextView {
  width: 440px;
  min-width: 440px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--white);
  box-shadow: 1px 0px 5px 0px var(--black_2);
  overflow: auto;
  transform: translateX(-100%);
  transition: transform 0.3s;
  margin-left: -440px;
  pointer-events: all;

  .pr2--panelClose {
    position: absolute;
    top: 24px;
    right: 20px;
    cursor: pointer;
    z-index: 7;
  }

  .lp--teacherPanel-contextView-header {
    display: flex;
    flex-direction: column;

    margin-bottom: 72px;

    .lp--userAvatar {
      width: 35px;
      height: 35px;
      display: inline-block;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: var(--standardBlackBackground);
      display: flex;
      align-items: center;
      flex-shrink: 0;
      display: block;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      align-content: center;
    }

    .lp--timeRemaining {
      .lp--timeRemainingInput {
        cursor: pointer;
        border: none;
        outline: none;
      }
    }
  }

  .lp--teacherPanelFooter {
    margin-top: auto;

    .lp--teacherPanelDeleteParticipant {
      background-color: var(--transparent);
      border: none;
      outline: none;
      background-image: url(../../../public/assets/images/editor/delete-icon-black.svg);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 17px 17px;
      padding-left: 22px;
      opacity: 0.7;
      transition:
        opacity 0.2s ease,
        text-decoration 0.2s ease;

      &:hover,
      &:focus {
        text-decoration: underline;
        opacity: 1;
        transition:
          opacity 0.2s ease,
          text-decoration 0.2s ease;
      }
    }

    .lp--teacherPanel-student-export {
      display: inline-block;

      .lp--teacherPanelExportStudentProgress {
        background-color: var(--transparent);
        border: none;
        outline: none;
        background-image: url(../../../public/assets/images/icons/download-file.svg);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 18px 20px;
        padding-left: 24px;
        margin-left: 5px;
        transition: text-decoration 0.2s ease;

        &:hover,
        &:focus {
          text-decoration: underline;
          transition: text-decoration 0.2s ease;
        }
      }
    }
  }

  h1 {
    color: var(--black_7);
    border-bottom: 1px solid var(--black_2);
  }

  .lp--teacherPanel-contextList-header {
    color: var(--black_8);
  }

  &.open {
    transform: translateX(0%);
    margin-left: 0px;
  }
}

.lp--teacherPanel-context-student,
.lp--teacherPanel-context-teacher {
  .lp--teacherPanel-contextView-header {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}

.lp--teacherPanel-context-student {
  .lp--teacherPanel-contextList-headers {
    margin-right: -5px;
  }
}

.lp--teacherPanelAcceptAllReports,
.lp--teacherPanelResetAllReports {
  border-top: 1px solid var(--black_2);
  padding: 20px 0;
  color: var(--black_7);
  font-size: 13px;
  justify-content: space-between;
  display: flex;

  span {
    display: flex;
    align-items: center;
  }

  .lp--teacherPanelAcceptAllReports-btn,
  .lp--teacherPanelResetAllReports-btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin-left: 10px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
    border: 2px solid var(--buttonGreenFront);
    font-size: 14px;
    font-weight: bold;
    background-color: var(--transparent);
    color: var(--black);
    transition:
      color 0.2s ease,
      background-color 0.2s ease;

    &:hover,
    &:focus {
      color: var(--black);
      background-color: var(--buttonGreenFront);
      transition:
        color 0.2s ease,
        background-color 0.2s ease;
    }
  }
}

.lp--teacherPanel-students {
  .lp--teacherPanel-contextList {
    margin-bottom: 48px;

    & > ul {
      margin-bottom: 0;
    }
  }

  .lp--teacherPanel-student-export {
    display: flex;
    justify-content: flex-end;

    .lp--teacherPanelExportStudentProgress {
      background-color: var(--transparent);
      border: none;
      outline: none;
      background-image: url(../../../public/assets/images/icons/download-file-white.svg);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 18px 20px;
      padding-left: 24px;
      margin-left: 5px;
      opacity: 0.8;
      transition:
        opacity 0.2s ease,
        text-decoration 0.2s ease;
      box-shadow: none;

      &:hover,
      &:focus {
        opacity: 1;
        transition:
          opacity 0.2s ease,
          text-decoration 0.2s ease;
        text-decoration: underline;
      }
    }
  }
}

.lp--teacherPanel-contextListItem {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  margin-left: -30px;
  margin-right: -30px;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: var(--white);
    transition: color 0.2s ease;
  }

  &.open {
    color: var(--white);
    background: var(--white_1);
  }

  .lp--contextListItem-textWrapper {
    overflow: hidden;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.lp--contextListItem-student {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  select {
    margin-left: 10px;
    padding: 5px;
  }
}

.lp--contextListItem-progress {
  display: flex;
  align-items: center;
}

.lp--contextListItem-progressIcon {
  &:after {
    display: block;
    width: 26px;
    height: 26px;
    content: ' ';
    background-size: cover;
  }

  &:after,
  &.blank:after {
    background-image: url(../../../public/assets/images/theme-2/icons/states/not-started.svg);
  }

  &.feedback:after {
    background-image: url(../../../public/assets/images/theme-2/icons/states/feedback-loop-_want-feedback.svg);
  }

  &.active:after {
    background-image: url(../../../public/assets/images/theme-2/icons/states/active.svg);
  }

  &.done:after {
    background-image: url(../../../public/assets/images/theme-2/icons/states/submitted.svg);
  }

  &.accepted:after {
    background-image: url(../../../public/assets/images/theme-2/icons/states/accepted.svg);
  }
}

.lp--progress-progressBar {
  width: 50px;
  height: 8px;
  background: var(--white_1);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.lp--progress-progressBar .lp--progress-progressBar-initiated,
.lp--progress-progressBar .lp--progress-progressBar-read,
.lp--progress-progressBar .lp--progress-progressBar-completed {
  height: 100%;
  position: absolute;
  border-radius: 4px;
  transition: 0.5s;
}

.lp--progress-progressBar .lp--progress-progressBar-initiated {
  background: var(--toolbarButtonBlue);
}

.lp--progress-progressBar .lp--progress-progressBar-completed {
  background: var(--progressBarGreen);
}

.lp--progress-progressBar .lp--progress-progressBar-read {
  background: var(--menuButtonBorder);
}

.lp--teacherPanel-contextView {
  padding: 18px 30px;

  .lp--teacherPanel-contextListItem {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    cursor: pointer;
    padding: 0 30px;
    margin-left: -30px;
    margin-right: -30px;
    transition: background-color 0.2s ease-in-out;
    color: var(--black_8);
    background-color: var(--white);

    &.lp--teacherPanel-contextListItemInert {
      cursor: inherit;
      &:hover,
      &:focus {
        color: inherit;
        background: inherit;
        transition: background-color 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus,
    &.open {
      transition: background-color 0.2s ease-in-out;
      background-color: var(--standardTextGrey);
    }
  }

  .lp--progress {
    .lp--progress-progressBar {
      width: 300px;
      height: 23px;
      padding: 2px;
      background: var(--transparent);
      border-radius: 11px;
      border: 1px solid var(--greyBorder);
      position: relative;
      margin-bottom: 27px;

      &:after {
        content: ' ';
        display: block;
        border: 4px solid var(--white);
        position: absolute;
        border-radius: 11px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
      }
    }

    .lp--progress-progressBar .lp--progress-progressBar-initiated,
    .lp--progress-progressBar .lp--progress-progressBar-read,
    .lp--progress-progressBar .lp--progress-progressBar-completed {
      border-radius: 11px;
      height: 17px;
    }

    .lp--progress-progressBar .lp--progress-progressBar-read {
      background: var(--greyBorder);
    }
  }
}

.lp--teacherPanel-behaviorPanel {
  background: var(--white);
  height: 100%;
  overflow: auto;
  max-width: 100%;
  min-width: 630px;
  width: 630px;
  position: relative;
  z-index: -1;
  transform: translateX(-100%);
  transition: 0.3s;
  box-shadow: 1px 0px 5px 0px var(--black_2);
  margin-left: -100%;
  pointer-events: all;

  &.open {
    transform: translateX(0%);
    margin-left: 0;
  }
}

.lp--modal {
  overflow-y: auto;
}

.lp--teacherPanel-teacherPaneWrapper {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

/* Task panels */

.lp--teacherPanel-context-group {
  .node-pane {
    z-index: 6;
  }

  .lp--contextListItem-student {
    font-size: 14px;
    color: var(--black_8);
  }

  .right-icon-menu {
    position: absolute;
    right: 0;
  }

  .lp--taskGroupParticipantsList,
  .task-viewer .users_list,
  .pr2--nodePanelBody.task-viewer ul.lp--taskGroupParticipantsList,
  .pr2--nodePanelBody.task-viewer .users_list {
    width: 100%;
    position: relative;

    li {
      list-style-type: none;

      &.TaskParticipation {
        padding: 0;
      }
    }

    h1 {
      width: 100%;
      padding: 0 0px 10px 0px;
    }

    .lp--taskReportGroup-nameField {
      position: absolute;
      top: -5px;
      left: 0px;
      width: 100%;
      text-indent: 0;
      padding: 0;
      font-size: 13px;
      line-height: 25px;
      border: 0px solid var(--transparent);
      background: var(--white);
      display: block;
      opacity: 0;
      outline: none;
    }
    .lp--taskReportGroup-nameField:focus {
      opacity: 1;
    }
  }

  .taskReportRow {
    background: var(--transparent) !important;
    margin-bottom: 20px;
    border-radius: 5px;

    .lp--taskGroupParticipantsList {
      min-height: 100px;
    }

    &:last-child {
      border: 0px solid var(--transparent);
    }
  }
  .task-viewer .users_pool {
    background: var(--transparent) !important;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px 0;
    min-height: 100px;
  }
  .task-viewer .users_list,
  .task-viewer .taskreports li.taskReportRow {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .TaskParticipation {
    padding: 0 10px;
    margin: 0;
  }

  .lp--panelGroupSelect {
    margin-left: 10px;
    margin-right: auto;
  }
  .task-viewer .users_list li {
    margin: 0;
  }
  .pr2--nodePanelBody {
    padding: 0px 30px 0px 30px;
    margin-bottom: 8px;
  }
  .taskreports .TaskParticipation {
    margin: 0;
  }

  .lp--teacherPanel-contextListItem {
    max-width: calc(100% + 20px);
    margin: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .pr2--panelHeaderSection {
    margin-bottom: 10px;
  }
  .lp--teacherPanelAcceptAllReports,
  .lp--teacherPanelResetAllReports {
    margin: 0 30px;
  }
}

@media screen and (-ms-high-contrast: none) {
  .lp--teacherPanel {
    width: auto;
    justify-content: flex-start;
    overflow-x: auto;
    pointer-events: none;
  }
}

.lp--teacherPanel {
  .pr2--circlePanelActivities {
    margin: 0;
    padding: 0;
  }
}

.toggle-content {
  display: none;
}

.lp--teacherPanel {
  .node-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggle-content {
      display: block;
      color: var(--darkHorizonBackground);
      opacity: 0.8;
      text-decoration: none;
      transition:
        opacity 0.2s ease,
        text-decoration 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        text-decoration: underline;
        transition:
          opacity 0.2s ease,
          text-decoration 0.2s ease;
      }
    }
  }

  .content-viewer {
    display: none;

    &.show {
      display: block;
    }
  }

  .panel-navigation {
    &.hub {
      height: 48px;
      margin: 20px -20px 0;

      &:hover,
      &:focus {
        cursor: default;
        background-color: var(--standardComponentBackground);
        color: var(--hoverGrey);
      }
    }

    &.hub:first-of-type {
      margin-top: 0;
    }

    .hub-image {
      width: 26px;
      height: 26px;
      background-size: 26px auto;
      position: relative;
      top: 0;
      left: 0;
      margin-left: 10px;
    }

    .hub-name {
      margin-left: 20px;
      font-size: 16px;
      font-weight: normal;
      color: var(--white);
    }
  }

  .lp--teacherPanel-contextListItem {
    &.node {
      margin: 3px -30px;
    }

    .node-name {
      font-size: 13px;
      color: var(--ghostWhite);
      padding-left: 15px;
    }

    .text-progress {
      font-weight: bold;
      color: var(--buttonGreenFront);
      margin-right: 15px;
    }

    &.open,
    &:hover,
    &:focus {
      .text-progress {
        color: var(--white);
      }
    }
  }

  .pr2--circlePanelActivities {
    &.student {
      margin-bottom: 20px;

      .panel-navigation {
        .hub-name {
          color: var(--black);
        }

        &.hub {
          background-color: var(--white);
          border-color: var(--slateGrey);
        }
      }

      .lp--teacherPanel-contextListItem {
        .node-name {
          color: var(--black);
        }

        &.node {
          transition: background-color 0.2s ease-in-out;
          background-color: var(--white);

          &:hover,
          &:focus,
          &.open {
            transition: background-color 0.2s ease-in-out;
            background-color: var(--standardTextGrey);
          }
        }
      }
    }
  }
}

.lp--teacherPanel-contextSelection {
  .text-progress {
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }
}

.lp--teacherPanel-context-student .notification {
  margin-right: 25px;
}

.lp--teacherPanel-contextView {
  .notification {
    margin-right: 35px;
  }

  .lp--teacherPanel-contextView-header {
    margin-bottom: 20px;
  }

  .lp--timeRemaining {
    transition: color 0.2s ease-in-out;
    margin: 30px auto 10px auto;
    color: var(--black_7);
    cursor: pointer;
    display: flex;

    &:hover,
    &:focus {
      transition: color 0.2s ease-in-out;
      color: var(--black);
    }

    &::before {
      content: ' ';
      display: inline-block;
      width: 24px;
      height: 22px;
      background: url('../../../public/assets/images/theme-2/icons/calendar.svg') center center no-repeat;
      flex-shrink: 0;
      margin-right: 5px;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .student-progress {
    display: flex;
    color: var(--behaviorGreenBack);
    margin: 0 auto;
    font-weight: bold;

    .text-progress {
      margin-right: 5px;
    }

    .lp--progress-progressBar-completed {
      width: 154px;
    }
  }
}

.lp--teacherPanel-context-private_blog,
.lp--teacherPanel-context-evaluation,
.lp--teacherPanel-context-quiz,
.lp--teacherPanel-context-legend,
.lp--teacherPanel-context-correlation,
.lp--teacherPanel-context-chunk {
  .lp--teacherPanel-contextView-header {
    margin-bottom: 82px;
  }
}

.lp--teacherPanel-context-group {
  .lp--teacherPanel-contextView-header {
    padding: 18px 30px;
  }

  .lp--taskGroupParticipantsList h1 {
    margin-bottom: 11px;
  }

  .lp--contextListItem-student {
    justify-content: space-between;
    width: 100%;
  }

  .users_pool {
    margin-top: 22px;
  }
}

.progress-indicator {
  background: var(--greyBorder);

  .circle-pane & {
    background: var(--white_1);
  }
}

.indicators {
  display: flex;
  align-items: center;

  > div {
    margin-left: 15px;
    margin-right: 15px;
  }

  .lp--teacherPanel-contextList-header {
    &.student-progress {
      width: 50px;
    }

    &.student-notification {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  .progress-icon {
    background-image: url('../../../public/assets/images/theme-2/icons/hide.svg');
    width: 27px;
    height: 20px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    opacity: 0.8;
    transform: scale(1);
    transition:
      opacity 0.2s ease,
      transform 0.2s ease;

    &.show {
      background-image: url('../../../public/assets/images/theme-2/icons/show.svg');
    }

    &:hover,
    &:focus {
      opacity: 1;
      transform: scale(1.2);
      transition:
        opacity 0.2s ease,
        transform 0.2s ease;
    }
  }

  .notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-size: cover;
    background-color: var(--behaviorPink);
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  .checked-out.show + .notification {
    display: none;
  }

  .checked-out {
    background: url('../../../public/assets/images/theme-2/green-checkmark.svg') no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    margin-right: 15px;
    display: none;

    &.show {
      display: block;
    }
  }
}

.student-checked-out {
  margin: 20px auto;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: none;

  &.show {
    display: block;
  }
}

.ui-state-highlight {
  background: var(--organizationGreen) !important;
}
