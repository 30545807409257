#panel-wrapper {
  position: fixed;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
  z-index: 7;
  pointer-events: none;
}

#panel-mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
}

.lp--loopEditor {
  #panel-mask {
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
}

.lp--modal ~ #panel-mask {
  z-index: 3;
  opacity: 0.7;
  pointer-events: all;
}

.coms-pane {
  background-color: var(--white);
  color: var(--standardComponentBackground);
  box-shadow: 0px 3px 13px 0px var(--black_2);
  width: 50%;
  min-width: 500px;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 2;
  right: -50%;
  overflow-y: auto;

  .pr2--panelHeaderSection {
    border-bottom: 1px solid var(--greyBorder);
    position: relative;
    justify-content: flex-start;

    .pr2--panelClose {
      flex: none;
      align-self: flex-start;
    }
  }

  .pr2--panelFooter {
    height: auto;
  }

  .pr2--nodePanelBody {
    padding: 0px 32px;
  }
}

.lp--teacherPanel {
  .coms-pane {
    position: static;
    width: 560px;
    pointer-events: all;
  }

  .pr2--panelHeaderSection {
    .pr2--panelHeaderToolFullscreen {
      display: none;
    }
  }
}

.node-pane {
  background-color: var(--white);
  color: var(--standardComponentBackground);
  box-shadow: 0px 3px 13px 0px var(--black_2);
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
  scroll-behavior: smooth;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.hub-pane {
  background-color: var(--standardComponentBackground);
  color: var(--white);
  width: 434px;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.circle-pane {
  background-color: var(--standardComponentBackground);
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 434px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .pr2--panelClose {
    background-image: url('../../../public/assets/images/theme-2/close-white.svg');
  }

  .pr2--panelHeaderSection {
    .pr2--panelHeaderToolFullscreen {
      display: none;
    }
  }
}

.lp--loopEditor {
  .coms-pane {
    top: 2px;
  }

  .node-pane {
    top: 2px;
    left: 2px;
  }

  .hub-pane {
    top: 2px;
    left: 2px;
  }

  .circle-pane {
    top: 2px;
    left: 2px;

    .pr2--panelHeader {
      margin: 40px 0px 22px 0px;
    }
  }
}

.node-pane,
.hub-pane,
.circle-pane,
.coms-pane {
  margin-top: 60px;
  height: calc(100% - 60px);
}

.lp--loopEditor {
  .node-pane,
  .hub-pane,
  .circle-pane,
  .coms-pane {
    height: calc(100% - 65px);
  }
}

.lp--teacherPanel {
  .node-pane,
  .hub-pane,
  .circle-pane,
  .coms-pane {
    margin-top: 0;
    height: calc(100% - 4px);
  }
}

.panel-content {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.content-panel {
  pointer-events: all;
  transition:
    transform 0.5s,
    opacity 0.5s,
    top 0.5s,
    width 0.4s;
}

#nodeContentEditorWrapper,
#nodeContentPreviewWrapper,
#nodeContentViewerWrapper {
  height: 100%;
}

.content-panel.hub-pane {
  transform: translateX(-532px);

  &.lp--fullScreenPanel {
    transform: translateX(-100%);
  }
}

.content-panel.hub-pane.open {
  transform: translateX(0px);
}

.content-panel.node-pane {
  transform: translateX(-720px);

  &.lp--fullScreenPanel {
    transform: translateX(-100%);
  }
}

.content-panel.node-pane.noAnimation {
  transition: none !important;
}

.content-panel.node-pane.open {
  transform: translateX(0px);
}

.content-panel.circle-pane {
  transform: translateX(-434px);

  &.lp--fullScreenPanel {
    transform: translateX(-100%);
  }
}

.content-panel.circle-pane.open {
  transform: translateX(0px);
}

.content-panel.coms-pane {
  transform: translateX(0px);
}

.content-panel.coms-pane.open {
  transform: translateX(-100%);
}

.lp--teacherPanel .lp--teacherPanel-behaviorPanel {
  .node-pane {
    width: 100%;
  }
}

.node-pane {
  &.lp--teacherPanel-nodePane {
    max-width: 100%;
  }

  &.lp--fullScreenPanel {
    width: 100%;
  }
}

.hub-pane,
.circle-pane {
  &.lp--fullScreenPanel {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1160px) and (orientation: landscape) {
  .node-pane {
    width: 50%;
  }

  .content-panel.node-pane {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 1160px) {
  .content-panel.coms-pane.open {
    min-width: 0;
  }
}

@media screen and (min-width: 1160px) {
  .node-pane {
    width: 720px;
  }

  .coms-pane {
    right: auto;
    max-width: 610px;
    width: 40%;
  }

  .lp--teacherPanel {
    .node-pane {
      width: 100%;
    }

    .coms-pane {
      right: initial;
      margin-right: auto;
      flex: none;
      transform: translateX(-100%);
      transition: 0.3s;
      z-index: -2;

      &.open {
        transform: translateX(0%);
      }
    }
  }

  .content-panel.coms-pane {
    transform: translateX(100px);
  }

  .content-panel.coms-pane.open {
    transform: translateX(720px);
    width: calc(100% - 720px);
    min-width: 0;
  }

  .lp--fullScreenPanel {
    .panel-content {
      width: 80%;
      max-width: 1200px;
      margin-left: 10%;
      margin-right: 10%;
    }

    .pr2--nodePanelBody,
    .lp--nodePanelUserSection,
    .pr2--circlePanelDescription,
    .lp--hubNavigation {
      font-size: 21px;
    }

    .circle-meta-container {
      font-size: 15px;
    }

    .circle-description__body {
      margin-left: 300px;
      margin-right: 300px;
    }

    .pr2--hubPanelDescription {
      font-size: 30px;
    }

    .pr2--panelHeader {
      .pr2--nodePaneName,
      .pr2--hubPaneName,
      .pr2--circlePaneName {
        font-size: 40px;
      }
    }

    .circle-panel-meta,
    .circle-panel-activities,
    .circle-panel-description__close_icon {
      display: none;
    }
  }
}

.lp--hubNavigation {
  h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--grey);
  }
}

.lp--hubNavigation-activities {
  margin-bottom: 70px;
}

li.lp--panelNavigation-activity {
  margin-bottom: 20px;

  > a {
    min-height: 21px;
  }

  &.hub {
    margin-bottom: 30px;
  }
}

.lp--panelNavigation-activity {
  font-size: 15px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px auto;
  padding-left: 37px;
  min-height: 20px;
  position: relative;
  min-height: 26px;
  display: flex;
  align-items: center;

  &.hub {
    font-size: 18px;
  }

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition-property: color;
    transition-duration: 0.2s;
    color: var(--white_7);

    &:hover,
    &:focus {
      color: var(--white);
    }
  }

  .lp--panelNavigationActivity-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px auto;
    background-color: var(--transparent);

    .circle-pane &,
    .hub-pane & {
      background-color: var(--black_9);
    }
  }

  .pr2--listIconWrapper-done {
    border: 2px solid var(--menuButtonBorder);
    background-size: 10px auto;
  }

  .pr2--listIconWrapper-legend {
    border: 2px solid var(--behaviorYellow);
    background-size: 8px auto;
  }

  .pr2--listIconWrapper-energy {
    border: 2px solid var(--behaviorYellow);
  }

  .pr2--listIconWrapper-chunk {
    border: 2px solid var(--behaviorYellow);
    background-size: 13px auto;
  }

  .pr2--listIconWrapper-video {
    border: 2px solid var(--behaviorYellow);
    background-size: 8px auto;
    background-position: 8px 5px;
  }

  .pr2--listIconWrapper-link {
    border: 2px solid var(--behaviorYellow);
    background-size: 12px auto;
  }

  .pr2--listIconWrapper-group {
    border: 2px solid var(--behaviorGreen);
    background-size: 14px auto;
    background-position: 4px 4px;
  }

  .pr2--listIconWrapper-private_blog {
    border: 2px solid var(--behaviorBlue);
    background-size: 11px auto;
    background-position: 6px 5px;
  }

  .pr2--listIconWrapper-public_blog {
    border: 2px solid var(--behaviorGreen);
    background-size: 10px auto;
  }

  .pr2--listIconWrapper-quiz {
    border: 2px solid var(--behaviorPink);
  }

  .pr2--listIconWrapper-written_quiz {
    border: 2px solid var(--behaviorBlue);
    background-size: 8px auto;
  }

  .pr2--listIconWrapper-task {
    border: 2px solid var(--behaviorBlue);
    background-size: 7px auto;
  }

  .pr2--listIconWrapper-correlation {
    border: 2px solid var(--behaviorPink);
    background-size: 10px auto;
  }

  .pr2--listIconWrapper-evaluation {
    border: 2px solid var(--behaviorPurple);
    background-size: 9px auto;
  }

  .pr2--listIconWrapper-checklist {
    border: 2px solid var(--behaviorBlue);
    background-size: 60%;
  }

  .pr2--listIconWrapper-code,
  .pr2--listIconWrapper-locked {
    &::after {
      content: ' ';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-size: 42px;
      background-size: 42px;
      background-position: center center;
    }
  }

  .lp--panelNavigationActivity-icon.accepted::after {
    content: '';
    display: block;
    background: url('../../../public/assets/images/theme-2/icons/accepted.svg') no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    right: -3px;
    bottom: -1px;
  }

  .pr2--listIconWrapper-code {
    &::after {
      background-image: url('../../../public/assets/images/theme-2/icons/graph/node-passwd-protected.png');
    }
  }

  .pr2--listIconWrapper-locked {
    &::after {
      background-image: url('../../../public/assets/images/theme-2/icons/graph/node-locked.png');
    }
  }

  &.pr2--listIconWrapper-home {
    background-size: 13px auto;
    position: relative;
    padding-left: 0px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    .pr2--listIconWrapper-homeIcon {
      width: 42px;
      height: 42px;
      margin-right: 9px;
      border-radius: 100px;
      background-size: 42px auto;
      display: inline-block;
      left: 0px;
      position: relative;
      top: 0px;
      background-color: var(--menuButtonBorder);
      flex-shrink: 0;
    }
  }
}

.node-pane {
  .lp--panelNavigation-activity {
    color: var(--black);

    .nav-node-name {
      border-bottom: 2px solid var(--transparent);
      transition: border-color 0.2s ease-in-out;
      padding-bottom: 5px;
      margin-bottom: -5px;

      &:hover,
      &:focus {
        border-color: var(--black_5);
        transition: border-color 0.2s ease-in-out;
      }
    }

    &.pr2--listIconWrapper-home {
      margin-top: 0;
    }
  }
}

.nav-node-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px;

  .node-pane & {
    max-width: 250px;
  }

  @media screen and (max-width: 767px) {
    max-width: 250px;

    .node-pane & {
      max-width: 80px;
    }
  }

  .lp--fullScreenPanel & {
    max-width: 100%;
  }
}

.hub-pane {
  .lp--panelNavigation-activity {
    &.pr2--listIconWrapper-home {
      margin-top: 0;
    }
  }
}

.lp--panelNavigation {
  display: flex;
  padding: 0 20px;
  margin-top: auto;
  height: 80px;
  align-items: center;
  border-top: 1px solid var(--black_1);
  font-size: 15px;
  position: sticky;
  bottom: 0;
  background: var(--white);
  z-index: 2;
  justify-content: space-between;

  .lp--panelNavigation-nextColumn {
    margin-left: auto;
  }

  .lp--hubNavigation + & {
    border-top: 1px solid var(--white_1);
    background: var(--standardComponentBackground);
  }

  .pr2--listIconWrapper-home {
    font-size: 20px;
  }

  .navigation-arrow {
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../public/assets/images/theme-2/icons/arrow.svg');
    transform: scale(1);
    transition:
      opacity 0.2s ease,
      transform 0.2s ease;
    flex-shrink: 0;

    &:hover,
    &:focus {
      opacity: 1;
      transform: scale(1.2);
      transition:
        opacity 0.2s ease,
        transform 0.2s ease;
    }
  }

  .navigation-arrow--up {
    transform: rotate(-90deg);
    margin-right: 10px;

    &:hover,
    &:focus {
      transform: scale(1.2) rotate(-90deg);
    }
  }

  .navigation-arrow--right {
    margin-left: 10px;
  }
}

.pr2--panelClose {
  width: 18px;
  height: 18px;
  margin: 0px 0px 0px 25px;
  background-size: 18px 18px;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
}

/*    Hub Panes     */

.hub-pane .pr2--panelClose {
  background-image: url('../../../public/assets/images/theme-2/close-white.svg');
}

.medium-editor-element iframe {
  width: 100%;
}

.pr2-hubPanelBody p:last-child,
.pr2--nodePanelBody p:last-child {
  margin-bottom: 15px;
}

/*    Coms Pane   */

.pr2-comsPanelCircleInfo {
  margin: 20px 20px;
  color: var(--slateGrey);
}

.pr2-comsPanelCircleInfo h1 {
  font-size: 22px;
  font-family: 'Helvetica';
  font-weight: bold;
  margin: 0px;
}

/*    Node Pane     */

.node-pane .pr2--panelClose,
.coms-pane .pr2--panelClose,
.lp--teacherPanel .pr2--panelClose {
  background-image: url('../../../public/assets/images/tmp/v2/node-panel-close.svg');
}

.node-pane .pr2--paneEditMode {
  .pr2--panelDeleteBtn {
    background-image: url('../../../public/assets/images/editor/delete-icon-black.svg');
    color: var(--black);
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      transition: opacity 0.2s ease;
      opacity: 1;
    }
  }
}

/*  Viewer  */
.pr2--nodePanelBody .content-viewer {
  margin-bottom: 30px;
}

.pr2--nodePanelBody .content-viewer,
.pr2--nodePanelBody.task-viewer,
.pr2--hubPanelDescription .content-viewer,
.pr2--nodePanelBody .medium-editor-container,
.pr2-hubPanelBody .content-viewer,
.pr2--hubPanelDescription .medium-editor-container,
.pr2--circlePanelDescription,
.pr2--nodePanelBody.task-viewer,
.pr2--nodePanelBody .evaluation-content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 20px;
  }
}

.lp--webcamRecording,
.lp--audioRecording,
.audio-attachment {
  width: 100%;
  outline: none;
}

.pr2--nodePanelBody .content-viewer h2,
.pr2--nodePanelBody.task-viewer h2,
.pr2--hubPanelDescription .content-viewer h2,
.pr2--nodePanelBody .medium-editor-container h2,
.pr2-hubPanelBody .content-viewer h2,
.pr2--hubPanelDescription .medium-editor-container h2,
.pr2--circlePanelDescription h2,
.pr2--nodePanelBody.task-viewer h2,
.pr2--nodePanelBody .evaluation-content h2 {
  font-size: 25px;
  margin-bottom: 20px;
}

.pr2--nodePanelBody .content-viewer img,
.pr2--nodePanelBody.task-viewer img,
.pr2--hubPanelDescription .content-viewer img,
.pr2--nodePanelBody .medium-editor-container img,
.pr2-hubPanelBody .content-viewer img,
.pr2--hubPanelDescription .medium-editor-container img,
.pr2--circlePanelDescription img,
.pr2--nodePanelBody.task-viewer img,
.pr2--nodePanelBody .evaluation-content img {
  max-width: 100%;
  cursor: zoom-in;
}

.pr2--nodePanelBody .content-viewer a img,
.pr2--nodePanelBody.task-viewer a img,
.pr2--hubPanelDescription .content-viewer a img,
.pr2--nodePanelBody .medium-editor-container a img,
.pr2-hubPanelBody .content-viewer a img,
.pr2--hubPanelDescription .medium-editor-container a img,
.pr2--circlePanelDescription a img,
.pr2--nodePanelBody.task-viewer a img,
.pr2--nodePanelBody .evaluation-content a img {
  cursor: pointer;
}

.lp--loopEditor {
  .pr2--nodePanelBody .content-viewer img,
  .pr2--nodePanelBody.task-viewer img,
  .pr2--hubPanelDescription .content-viewer img,
  .pr2--nodePanelBody .medium-editor-container img,
  .pr2-hubPanelBody .content-viewer img,
  .pr2--hubPanelDescription .medium-editor-container img,
  .pr2--circlePanelDescription img,
  .pr2--nodePanelBody.task-viewer img,
  .pr2--nodePanelBody .evaluation-content img {
    cursor: default;
  }
}

.pr2-hubPanelBody .content-viewer a,
.pr2--nodePanelBody .content-viewer a,
.pr2--nodePanelBody.task-viewer a,
.pr2--nodePanelBody .medium-editor-container a,
.pr2--hubPanelDescription .content-viewer a,
.pr2--hubPanelDescription .medium-editor-container a,
.pr2--circlePanelDescription a,
.pr2--nodePanelBody.task-viewer a,
.pr2--nodePanelBody .evaluation-content a {
  text-decoration: underline;
}

.pr2-hubPanelBody .content-viewer iframe,
.pr2--nodePanelBody .content-viewer iframe,
.pr2--nodePanelBody.task-viewer iframe,
.pr2--nodePanelBody .medium-editor-container iframe,
.pr2--hubPanelDescription .content-viewer iframe,
.pr2--hubPanelDescription .medium-editor-container iframe,
.pr2--circlePanelDescription iframe,
.pr2--nodePanelBody.task-viewer iframe,
.pr2--nodePanelBody .evaluation-content iframe {
  max-width: 100% !important;
  width: 100%;
}

.pr2--nodePanelBody .content-viewer ul,
.pr2--nodePanelBody.task-viewer ul,
.pr2--nodePanelBody.evaluation-viewer ul,
.pr2--hubPanelDescription .content-viewer ul,
.pr2--hubPanelDescription .evaluation-viewer ul,
.pr2--nodePanelBody .medium-editor-container ul,
.pr2-hubPanelBody .content-viewer ul,
.pr2-hubPanelBody .evaluation-viewer ul,
.pr2--hubPanelDescription .medium-editor-container ul,
.pr2--circlePanelDescription ul {
  list-style-position: inside;

  li {
    list-style-type: disc;
  }
}

.pr2--nodePanelBody .content-viewer,
.pr2--nodePanelBody.task-viewer,
.pr2--nodePanelBody.evaluation-viewer,
.pr2--hubPanelDescription .content-viewer,
.pr2--hubPanelDescription .evaluation-viewer,
.pr2--nodePanelBody .medium-editor-container,
.pr2-hubPanelBody .content-viewer,
.pr2-hubPanelBody .evaluation-viewer,
.pr2--hubPanelDescription .medium-editor-container,
.pr2--circlePanelDescription {
  :not(ul[data-checked]) li::before {
    content: '';
  }

  ul[data-checked] li {
    list-style-type: none;
  }

  ul[data-checked='true'] li::before {
    content: url(../../../public/assets/images/icons/checkbox-selected.svg);
    margin-right: 7px;
  }

  ul[data-checked='false'] li::before {
    content: url(../../../public/assets/images/icons/checkbox-unselected.svg);
    margin-right: 7px;
  }
}

.pr2--nodePanelBody .content-viewer,
.pr2--nodePanelBody.task-viewer,
.pr2--nodePanelBody.evaluation-viewer,
.pr2--nodePanelBody .medium-editor-container {
  ul[data-checked='true'] li::before {
    content: url(../../../public/assets/images/icons/checkbox-selected-white.svg);
  }

  ul[data-checked='false'] li::before {
    content: url(../../../public/assets/images/icons/checkbox-unselected-white.svg);
  }
}

.pr2--nodePanelBody .content-viewer ol,
.pr2--nodePanelBody.task-viewer ol,
.pr2--nodePanelBody.evaluation-viewer ol,
.pr2--hubPanelDescription .content-viewer ol,
.pr2--hubPanelDescription .evaluation-viewer ol,
.pr2--nodePanelBody .medium-editor-container ol,
.pr2-hubPanelBody .content-viewer ol,
.pr2-hubPanelBody .evaluation-viewer ol,
.pr2--hubPanelDescription .medium-editor-container ol,
.pr2--circlePanelDescription ol {
  list-style-position: inside;

  li {
    list-style-type: decimal;
  }
}

.pr2--nodePanelBody .content-viewer,
.pr2--nodePanelBody.task-viewer,
.pr2--nodePanelBody.evaluation-viewer,
.pr2--hubPanelDescription .content-viewer,
.pr2--hubPanelDescription .evaluation-viewer,
.pr2--nodePanelBody .medium-editor-container,
.pr2-hubPanelBody .content-viewer,
.pr2-hubPanelBody .evaluation-viewer,
.pr2--hubPanelDescription .medium-editor-container,
.pr2--circlePanelDescription,
.lp--nodePanelUserSection {
  .lp--activityEditorLinkBox {
    margin: 15px 0px;
    border: 1px solid var(--black_3);
    padding: 10px;
    text-align: center;
    border-radius: 8px;

    a {
      background-image: url('../../../public/assets/images/theme-2/icons/text-editor/link-out.svg');
      background-size: 17px 17px;
      background-repeat: no-repeat;
      background-position: left center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 20px;
      width: 100%;
      display: block;
    }
  }
}

/*      Circle Pane     */

.pr2--panelLogoSection {
  padding: 23px 0px 0px 31px;
  display: flex;
  flex: none;

  .pr2--panelClose {
    margin: 0px 20px 0px auto;
  }

  .pr2--panelLogoSection-panelTitle {
    font-size: 15px;
    color: var(--behaviorGreen);
  }
}

.pr2--panelLogoSection-logo {
  cursor: pointer;
}

.grade-1-3 {
  color: var(--behaviorPinkFront);
}

.grade-4-6 {
  color: var(--behaviorBlueFront);
}

.grade-7-9 {
  color: var(--behaviorGreenFront);
}

.grade-teacher {
  color: var(--behaviorPurpleFront);
}

.pr2--panelType {
  color: var(--black_7);

  p {
    margin: 0;
  }
}

.pr2--panelHeader {
  padding: 0px 45px 0px 42px;

  .pr2--circlePaneSubject {
    margin: 0px 0px 14px 0px;
    color: var(--white_7);
    font-size: 15px;
    cursor: pointer;
    width: fit-content;
    transition: color 0.2s ease;
    outline: none;

    &:hover,
    &:focus {
      color: var(--white);
      transition: color 0.2s ease;
    }
  }

  .pr2--circlePaneName,
  .pr2--hubPaneName,
  .pr2--nodePaneName {
    margin: 0px 0px 15px 0px;
    font-weight: bold;
    color: var(--white);
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 3px;
  }

  .pr2--nodePaneName {
    color: var(--darkHorizonBackground);
    font-size: 36px;
    margin: 0px;
    border: none;
    text-transform: none;
  }

  .lp--teacherPanelLink {
    margin-left: 16px;
    width: 26px;
    height: 26px;
    align-self: center;
    cursor: pointer;
    display: inline-block;
    background: url('../../../public/assets/images/theme-2/icons/link_arrow_green.svg') center center no-repeat;
    vertical-align: middle;
    margin-bottom: 2px;
    transform: scale(1);
    transition: transform 0.2s ease;

    &:hover,
    &:focus {
      transform: scale(1.2);
      transition: transform 0.2s ease;
    }
  }

  .lp--nodePaneComment {
    color: var(--darkHorizonBackground);
    margin: 15px 0px;
    display: inline-block;
    opacity: 0.8;
    position: relative;
    padding: 0px 0px 0px 30px;
    line-height: 25px;
    text-decoration: none;
    transition:
      opacity 0.2s ease,
      text-decoration 0.2s ease;

    &:before {
      margin: 0px 0px 0px auto;
      background-image: url('../../../public/assets/images/theme-2/icons/asterisk-white.svg');
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 11px;
      background-color: var(--black);
      position: absolute;
      left: 0px;
      content: ' ';
      width: 22px;
      height: 22px;
    }

    &:hover,
    &:focus {
      opacity: 1;
      text-decoration: underline;
      transition:
        opacity 0.2s ease,
        text-decoration 0.2s ease;
    }
  }

  .lp--circleDurationContainer {
    display: flex;

    .lp--circleDurationMarker {
      margin-right: auto;
      margin-left: 7px;
      color: var(--white_7);
    }
  }

  .pr2--circlePaneTime,
  .lp--circlePaneDuration {
    background-image: url('../../../public/assets/images/tmp/v2/timeleft.png');
    background-size: 15px 15px;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 0px 0px 0px 23px;
    color: var(--white);
    font-size: 15px;
  }

  .lp--circlePaneDuration {
    background-image: none;
    padding: 0px;
    color: var(--grey);
  }

  .pr2--circlePaneTimeInput {
    background-color: var(--transparent);
    border: none;
    outline: none;
    color: var(--white_9);
    padding: 0px;
    min-width: 20px;

    &:empty:before {
      content: attr(placeholder);
      display: block;
      /* For Firefox */
    }

    &:empty + .lp--circleDurationMarker {
      display: none;
    }
  }

  .pr2--circlePaneTimeInput::placeholder {
    color: var(--white_7);
  }
}

.hub-pane {
  .pr2--panelHeader {
    margin-bottom: 0px;
  }
}

.node-pane,
.hub-pane {
  .pr2--panelHeader {
    margin: 0px 0px 26px 0px;
  }

  .hub-panel-activity {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .node-accepted,
  .notification {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-size: cover;
    margin-right: 15px;
    visibility: hidden;
  }

  .indicators {
    display: flex;
  }

  .node-accepted {
    background-image: url('../../../public/assets/images/theme-2/icons/states/accepted.svg');
  }

  .notification {
    background-color: var(--behaviorPink);
  }

  .node-accepted.show,
  .notification.show {
    visibility: visible;
  }
}

.pr2--panelHeaderSection {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 20px 30px;
  background: var(--standardComponentBackground);
  position: sticky;
  top: 0;
  z-index: 3;

  .lp--panelHeaderComments {
    position: relative;
    padding-left: 24px;
    color: var(--black_7);

    &:before {
      left: 0px;
      top: 0px;
      width: 18px;
      z-index: 6;
      content: ' ';
      background-color: var(--black_4);
      background-image: url('../../../public/assets/images/theme-2/icons/asterisk-white.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: center center;
      height: 18px;
      border-radius: 9px;
      position: absolute;
    }
  }

  .pr2--panelHeaderTools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pr2--panelHeaderToolFullscreen {
    width: 16px;
    height: 16px;
    background-image: url('../../../public/assets/images/theme-2/icons/panel-header/fullscreen.svg');
    background-repeat: no-repeat;
    display: block;
    margin: 0px 18px 0px 0px;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover,
    &:focus {
      opacity: 0.8;
      transition: opacity 0.2s ease;
    }
  }

  @media screen and (max-width: 500px) {
    .pr2--panelHeaderToolFullscreen {
      display: none;
    }
  }

  .pr2--panelHeaderToolReadableFont,
  .pr2--panelHeaderToolImmersiveReader {
    opacity: 0.5;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover,
    &:focus {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }

  .pr2--panelHeaderToolReadableFont {
    background-image: url('../../../public/assets/images/theme-2/icons/panel-header/dyslexia.svg');
    width: 16px;
    height: 16px;
    margin: 0px 0px 0px 18px;

    .teacher-panel & {
      display: none;
    }
  }

  .pr2--panelHeaderToolImmersiveReader {
    background-image: url('../../../public/assets/images/theme-2/icons/panel-header/immersive-reader.svg');
    width: 18px;
    height: 18px;
  }
}

.panel-header-section {
  margin: 0;
}

.circle-description__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.circle-panel-description__fullscreen_icon,
.circle-panel-description__close_icon {
  width: 16px;
  height: 16px;
  background-image: url('../../../public/assets/images/theme-2/icons/panel-header/expand.svg');
  background-repeat: no-repeat;
  display: block;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
}

.circle-panel-description__close_icon {
  background-image: url('../../../public/assets/images/theme-2/close-white.svg');
  background-size: contain;
  margin-left: 20px;
}

@media screen and (max-width: 500px) {
  .circle-panel-description__fullscreen_icon {
    display: none;
  }
}

.pr2--panelLogoSection {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 0px 0px 0px;
  padding: 23px 0px 20px 42px;
  background: var(--standardComponentBackground);
  position: sticky;
  top: 0;
  z-index: 1;
}

.hub-pane {
  .pr2--panelHeaderSection,
  .pr2--panelLogoSection {
    background: var(--standardComponentBackground);
  }
}

.node-pane .pr2--panelHeaderSection,
.coms-pane .pr2--panelHeaderSection {
  background: var(--white);
}

.hub-pane,
.circle-pane {
  .pr2--panelHeaderSection {
    .pr2--panelHeaderToolReadableFont {
      background-image: url('../../../public/assets/images/theme-2/icons/panel-header/dyslexia-white.svg');
    }

    .pr2--panelHeaderToolFullscreen,
    .circle-panel-description__fullscreen_icon {
      background-image: url('../../../public/assets/images/theme-2/icons/panel-header/expand.svg');
    }

    .pr2--panelHeaderToolImmersiveReader {
      background-image: url('../../../public/assets/images/theme-2/icons/panel-header/immersive-reader-white.svg');
    }
  }
}

.hub-pane,
.circle-pane {
  &.lp--fullScreenPanel {
    .pr2--panelHeaderToolFullscreen,
    .circle-panel-description__fullscreen_icon {
      opacity: 0.5;
      background-image: url('../../../public/assets/images/theme-2/icons/panel-header/collapse-white.svg');
      transition: opacity 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }
}

.collapsed-description-panel {
  display: none;
}

.circle-pane .panel-header-section {
  display: flex;
  justify-content: space-between;
  color: var(--hoverGrey);
  padding: 13px 16px;

  .collapsed-description-panel {
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    display: block;
    border: 1px solid var(--menuButtonBorder);
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--white);
      transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
      background-color: var(--standardComponentBackground);
    }

    &.collapse {
      visibility: hidden;
    }
  }
}

.hub-pane .panel-header-section {
  .collapsed-description-panel {
    display: block;
    &.collapse {
      visibility: hidden;
    }
  }
}

.readability {
  .pr2--panelHeaderToolReadableFont {
    opacity: 1;
  }
}

.pr2--paneEditMode {
  .pr2--circlePaneName,
  .pr2--hubPaneName,
  .pr2--nodePaneName {
    background-color: var(--transparent);
    padding: 0px;
    margin: 0 0 21px 0;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: content-box;
    resize: none;

    &::placeholder {
      color: var(--white_8);
      font-style: italic;
      text-indent: -4px;
    }
  }

  .pr2--nodePaneName::placeholder {
    color: var(--black_4);
  }

  .pr2--nodePaneName,
  .pr2--hubPaneName {
    margin-bottom: 0px;
  }

  .pr2--circlePaneSubject {
    background-color: var(--transparent);
    padding: 0px;
    margin: 0px;
    border: none;
  }

  .lp--activityEditorSection {
    border: none;
    border-radius: 0px;

    .lp--activityEditor,
    .ql-editor.ql-blank::before {
      left: 0;
      color: var(--white_8);
    }

    .lp--activityEditor {
      padding: 0px;
      font-size: 15px;
    }

    .lp--activityEditorImage,
    .lp--activityEditorVideo,
    .lp--activityEditorAudio {
      background-color: var(--transparent);
      color: var(--grey);
    }

    .ql-container {
      font-size: 15px;
      font-family: 'Roboto', sans-serif;

      .ql-editor {
        padding: 0px;
      }
    }

    .lp--activityEditorFooter {
      width: calc(100% - 30px);
      margin: 0px 0px 0px 38px;

      .lp--activityEditorFooterWrapper {
        padding-right: 0px;
      }
    }
  }

  .lp--activityEditor.media-texteditor {
    width: 100%;

    .ql-container.ql-snow {
      border: none;
    }
  }

  .pr2--nodePanelBody {
    .lp--activityEditor,
    .ql-editor.ql-blank::before {
      left: 0;
      color: var(--darkHorizonBackground);
    }

    .lp--activityEditorImage,
    .lp--activityEditorVideo,
    .lp--activityEditorAudio {
      background-color: var(--white);
      color: var(--black_5);
    }
  }

  .pr2--imageUploader {
    width: auto;
    padding: 0px 8px;
    height: 35px;
    border-radius: 18px;
    border: 2px solid var(--white_7);
    color: var(--white_7);
    transition:
      color 0.2s ease,
      border-color 0.2s ease;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      border-color: var(--white_9);
      color: var(--white_9);
      transition:
        color 0.2s ease,
        border-color 0.2s ease;
    }
  }

  .pr2--panelDeleteBtn {
    background-image: url('../../../public/assets/images/editor/delete-icon.svg');
    background-repeat: no-repeat;
    background-position: left center;
    outline: none;
    border: none;
    background-color: var(--transparent);
    color: var(--white);
    opacity: 0.7;
    transition: opacity 0.2s ease;
    padding: 0 0 0 20px;
    height: 14px;
    display: flex;
    align-items: center;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      transition: opacity 0.2s ease;
      opacity: 1;
    }
  }

  .pr2--panelDeleteBtn:disabled {
    text-decoration: line-through;
  }

  .pr2--panelDeleteBtn:active {
    color: var(--white_8);
  }

  .pr2--circlePanelEditMeta {
    width: 336px;
    border: 1px solid var(--white_2);
    margin: 0px auto;
    border-radius: 10px;
    padding: 27px 25px;
    color: var(--white_7);
  }
}

.pr2--switchContainer {
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  margin-bottom: 18px;

  .pr2--switchLabel {
    cursor: pointer;
    font-weight: normal;
    color: var(--white_7);
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: var(--white_9);
      transition: color 0.2s ease;
    }
  }
}

.pr2--switchContainer:last-of-type {
  margin-bottom: 0px;
}

.pr2--slideContainer {
  width: 100%;

  label {
    margin-bottom: 5px;
  }
}

.slider {
  appearance: none;
  width: 100%;
  height: 24px;
  background: var(--minimumGreenIcon);
  border: 1px solid var(--white);
  border-radius: 60px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--white);
    cursor: pointer;
    box-shadow: 0 2px 5px var(--black_4);
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--white);
    cursor: pointer;
    box-shadow: 0 2px 5px var(--black_4);
  }
}

.node-pane {
  .pr2--switchContainer {
    .pr2--switchLabel {
      cursor: pointer;
      font-weight: normal;
      color: var(--white_7);
      transition: color 0.2s ease;

      &:hover,
      &:focus {
        color: var(--white_9);
        transition: color 0.2s ease;
      }
    }
  }
}

.pr2--panelFooter {
  background: var(--standardComponentBackground);
  margin-top: auto;
  z-index: 1;
  position: sticky;
  bottom: 0;

  .lp--writtenQuizFooter {
    .lp--activityEditorStateContainer {
      margin-right: 35px;
    }
  }

  .lp--privateBlogFooter {
    .lp--activityEditorStateContainer {
      margin-right: 35px;
    }

    .lp--activityEditorSubmit {
      margin: 0px 10px;
    }
  }

  .lp--articleTypeSwitch {
    margin-left: auto;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 4px 0px 4px 25px;
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/subcat.svg');

    &[data-type='video'] {
      background-image: url('../../../public/assets/images/theme-2/icons/grayscale/video.svg');
    }

    &[data-type='link'] {
      background-image: url('../../../public/assets/images/theme-2/icons/grayscale/link.svg');
    }

    color: var(--black_7);
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: var(--black_9);
      transition: color 0.2s ease;
    }
  }

  .lp--activityEditorStateSwitch .lp--activityStateSwitchList {
    bottom: 40px;
    top: auto;
    right: auto;
    left: -10px;

    &:after,
    &:before {
      top: 100%;
      bottom: auto;
      left: 20px;
    }

    &:after {
      border-top-color: var(--white);
      border-bottom: none;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before {
      border-top-color: var(--black_3);
      border-bottom: none;
      border-width: 11px;
      margin-left: -11px;
    }
  }

  .lp--panelLockBtn,
  .copy-node,
  .announce-object {
    position: relative;
    border: none;
    background-color: var(--transparent);
    outline: none;
    margin: 0px 0px 0px 15px;
    padding: 0px 0px 0px 20px;
    color: var(--white_7);
    transition: color 0.2s ease;

    &:after {
      width: 12px;
      background-size: 12px;
      height: 100%;
      position: absolute;
      opacity: 0.6;
      left: 0;
      top: 0px;
      content: ' ';
      background-repeat: no-repeat;
      background-position: center left;
      background-image: url('../../../public/assets/images/tmp/v2/icons/lockIcon@2x.png');
      transition: opacity 0.2s ease;
    }

    &:hover,
    &:focus {
      color: var(--white);
      transition: color 0.2s ease;

      &:after {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }

  .lp--panelLockBtn:after {
    top: -1px;
  }

  .announce-object {
    padding: 0px 0px 0px 25px;

    &:after {
      background-image: url('../../../public/assets/images/icons/megaphone.svg');
      background-size: 100%;
      width: 18px;

      .node-pane & {
        background-image: url('../../../public/assets/images/icons/megaphone-dark.svg');
      }
    }

    .node-pane & {
      color: var(--black_7);

      &:hover,
      &:focus {
        color: var(--darkHorizonBackground);
      }
    }
  }

  .copy-node {
    &:after {
      background-image: url('../../../public/assets/images/icons/copy-to-clipboard.svg');
      width: 15px;
      background-size: 100%;

      .node-pane & {
        background-image: url('../../../public/assets/images/icons/copy-to-clipboard-black.svg');
      }
    }

    .node-pane & {
      color: var(--black_7);

      &:hover,
      &:focus {
        color: var(--darkHorizonBackground);
      }
    }
  }

  .lp--panelBtnGroup {
    display: flex;
    margin-left: auto;
  }

  .lp--panelResetBtn {
    padding: 0px 15px;
    height: 36px;
    border-radius: 36px;
    outline: none;
    border: none;
    background-color: var(--transparent);
    border: 2px solid var(--black_2);
    font-size: 14px;
    font-weight: bold;
    color: var(--black_2);
  }

  .lp--panelRandomBtn,
  .lp--panelCorrectBtn {
    padding: 0px 15px;
    height: 36px;
    border-radius: 36px;
    outline: none;
    border: none;
    margin-left: auto;
    border: 2px solid var(--buttonGreenFront);
    font-size: 14px;
    font-weight: bold;
    background-color: var(--transparent);
    color: var(--black);
  }

  .lp--panelSaveDraftBtn,
  .pr2--panelSaveBtn,
  .lp--passwordPanelSubmit,
  .lp--panelSaveBtn {
    padding: 0 15px;
    height: 36px;
    border-radius: 36px;
    outline: none;
    margin-left: auto;
    border: 2px solid var(--buttonGreenFront);
    font-size: 14px;
    font-weight: bold;
    background-color: var(--transparent);
    color: var(--white);
    transition: background-color 0.2s ease;

    .node-pane & {
      color: var(--black);
    }

    &.pr2--hubPanelSaveBtn {
      margin-left: 8px;
    }

    &:hover,
    &:focus {
      color: var(--black);
      background-color: var(--buttonGreenFront);
      transition: background-color 0.2s ease;
    }

    .circle-pane &,
    .hub-pane & {
      border: 2px solid var(--buttonGreenFront);
      background-color: var(--transparent);
      color: var(--white);

      &:hover,
      &:focus {
        color: var(--black);
        background-color: var(--buttonGreenFront);
        transition: background-color 0.2s ease;
      }
    }
  }

  .lp--articleTypeSwitch + .pr2--panelSaveBtn {
    margin-left: 20px;
  }

  .lp--panelSaveDraftBtn {
    min-width: 105px;
    background-color: var(--black_2);
    margin: 0px 15px;
    color: var(--darkHorizonBackground);
    border: 2px solid var(--white);
  }

  .lp--panelSaveDraftBtn:disabled,
  .pr2--panelSaveBtn:disabled,
  .lp--panelSaveBtn:disabled {
    opacity: 0.5;
  }

  .lp--panelSaveDraftBtn:active {
    color: var(--white);
    background-color: var(--menuButtonBorder);
  }

  .lp--panelSaveBtn {
    margin-left: 20px;
  }
}

.node-pane .pr2--panelFooter {
  border-top: 1px solid var(--black_1);
  position: sticky;
  background: var(--white);
  bottom: 80px;
  z-index: 2;

  .lp--loopEditor & {
    bottom: 0;
  }
}

.lp--teacherPanel .pr2--panelFooter {
  bottom: 0;
}

.pr2--circlePanelDescription,
.pr2--nodePanelBody,
.lp--nodePanelUserSection,
.pr2--hubPanelDescription {
  padding: 0px 42px;
  color: var(--white_7);
  font-size: 15px;
  margin: 0px 0px 58px 0px;
  content: '\f019';

  &.pr2--hubPanelInventory {
    background-color: var(--black_1);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .text-editor-container + .text-editor-container {
    margin-top: 40px;
  }

  &.task-viewer,
  &.evaluation-viewer,
  &.quiz-viewer,
  &.written-quiz-viewer {
    margin: 0;
  }
}

.pr2--nodePanelBody {
  margin: 0;
}

@-moz-document url-prefix() {
  .panel-content.node-pane:after {
    content: '⁣';
    /* This content is not empty. It contains an invisible unicode character. Hacking around firefox quirk where the last element's bottom margin is not respected. */
    display: block;
    height: 1px;
    margin-top: -40px;
    z-index: -999;
    position: relative;
  }
}

.pr2--panelFooter + .lp--panelNavigation {
  margin-top: 0px;
}

.pr2--nodePanelBody,
.lp--nodePanelUserSection {
  color: var(--darkHorizonBackground);
  font-size: 14px;

  .lp--behaviorNotAvailableMessage {
    font-style: italic;
  }

  .users_list {
    list-style-type: none;
    min-height: 40px;
    padding-left: 0px;
    margin-bottom: 58px;
  }

  .users_list li {
    margin: 10px 9px 0px 0px;
    display: flex;
  }

  p {
    margin: 0px 0px 15px 0px;
  }
}

.student-section-placeholder {
  font-style: italic;
  font-size: 14px;
  color: var(--black_7);
  border-bottom: 2px solid var(--buttonGreenFront);
  border-top: 2px solid var(--buttonGreenFront);
  padding: 16px 42px;
  cursor: pointer;
}

.navigation-panel {
  padding: 0px 30px;
  margin-top: auto;
  height: 60px;
  display: flex;
  align-items: center;
  color: var(--black_7);

  .circle-pane &,
  .hub-pane & {
    border-top: 1px solid var(--white_2);
  }

  .panel-content.chunk & {
    justify-content: center;

    .lp--teacherPanel & {
      justify-content: initial;
    }
  }

  .info-icon {
    position: absolute;
    right: 50%;
    width: 32px;
    height: 32px;
    background-image: url('../../../public/assets/images/icons/info-green.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &.hidden {
      background-image: url('../../../public/assets/images/icons/info-white.svg');
      display: block !important;
    }

    &:hover,
    &:focus {
      transform: scale(1.2);
      transition: transform 0.2s ease-in-out;
    }
  }
}

.lp--nodePanelUserSection.preview {
  margin: 0;

  .ql-editor {
    white-space: initial;
  }

  .header-user-name {
    font-style: italic;
    color: var(--black);

    &.no-italic {
      font-style: normal;
    }

    p {
      margin: 0;
    }
  }

  &.published-post {
    .lp--activityEditorSection.student {
      border: 1px solid var(--greyBorder);

      .lp--activityEditorSectionHeader .editor-header-row {
        background-color: var(--white);
        color: var(--darkHorizonBackground);
        border-bottom: 1px solid var(--greyBorder);

        .header-time {
          font-style: normal;
          margin: 0;
        }
      }

      .blogpost-edit-panel {
        font-size: 12px;
        opacity: 0.5;
        white-space: nowrap;

        a.disabled {
          cursor: default;
        }
      }

      .lp--activityEditorSectionHeaderDeleteLink {
        margin-left: 10px;
      }

      .lp--activityEditor.student {
        border: none;
      }
    }
  }

  .lp--activityEditorSection.student {
    .lp--activityEditorSectionHeader {
      .header-time {
        font-style: normal;
        margin: 0;
      }
    }
  }
}

.lp--activityEditorContainer.preview {
  align-items: flex-end;
  justify-content: center;
  height: 60px;
  padding: 0 30px;
}

.pr2--panelFooter.preview {
  .navigation-panel {
    height: 60px;
  }
}

.pr2--circlePanelDescription :last-child,
.pr2--hubPanelDescription :last-child {
  margin-bottom: 0px;
}

.pr2--circlePanelSectionHeader {
  font-size: 24px;
  font-weight: bold;
  color: var(--hoverGrey);
  margin-bottom: 16px;
}

.progress-indicators {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text-progress {
    font-size: 12px;
    font-weight: bold;

    &.yellow {
      .circle-panel-activities & {
        color: var(--behaviorYellowFront);
      }
      #lp--teacherPanel & {
        color: var(--behaviorYellowBack);
      }
    }

    &.green {
      .circle-panel-activities & {
        color: var(--behaviorGreenFront);
      }
      #lp--teacherPanel & {
        color: var(--behaviorGreenBack);
      }
    }

    &.blue {
      .circle-panel-activities & {
        color: var(--behaviorBlueFront);
      }
      #lp--teacherPanel & {
        color: var(--behaviorBlueBack);
      }
    }

    &.pink {
      .circle-panel-activities & {
        color: var(--behaviorPinkFront);
      }
      #lp--teacherPanel & {
        color: var(--behaviorPinkBack);
      }
    }

    &.purple {
      .circle-panel-activities & {
        color: var(--behaviorPurpleFront);
      }
      #lp--teacherPanel & {
        color: var(--behaviorPurpleBack);
      }
    }
  }
}

.progress-indicator {
  width: 100%;
  margin: 0 3px 0 7px;
  height: 8px;
  background: var(--defaultPlaceholder);
  border-radius: 4px;

  &:first-of-type {
    margin-left: 0;
  }

  .percent-progress {
    height: 8px;
    border-radius: 4px;
    transition: 0.5s;

    &.yellow {
      background-color: var(--behaviorYellow);
    }

    &.green {
      background-color: var(--behaviorGreen);
    }

    &.blue {
      background-color: var(--behaviorBlue);
    }

    &.pink {
      background-color: var(--behaviorPink);
    }

    &.purple {
      background-color: var(--behaviorPurple);
    }
  }
}

.preview-text {
  margin: 20px 0;
  font-style: italic;
  font-size: 13px;
  opacity: 0.8;

  .quiz-viewer & {
    margin: 0 -42px;
    font-style: italic;
    position: sticky;
    bottom: 140px;
    opacity: 1;
    background-color: var(--white);
    border-top: 1px solid var(--hoverGrey);
    padding: 20px 42px;
    color: var(--black_7);
  }
}

.circle-purpose {
  margin-top: 20px;

  p {
    font-style: italic;
  }
}

.circle-meta-container {
  margin: 20px;
  font-size: 13px;

  .circle-subjects {
    padding-top: 16px;
  }

  .circle-duration__time {
    white-space: nowrap;
    text-align: center;
  }

  .circle-duration__time,
  .circle-duration__text {
    padding-bottom: 16px;
  }

  .space {
    width: 30px;
  }

  .curriculum__icon,
  .circle-share__icon {
    background-image: url(../../../public/assets/images/theme-2/icons/curriculum.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    width: 23px;
    height: 23px;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }

  .circle-share__icon {
    background-image: url(../../../public/assets/images/tmp/v2/icons/shareIcon.png);
    background-position: center center;
  }

  .circle-share__text {
    border-bottom: 1px solid var(--transparent);
    transition: border-color 0.2s ease;
    width: fit-content;

    &:hover,
    &:focus {
      border-color: var(--behaviorGreen);
      transition: border-color 0.2s ease;
    }
  }

  .curriculum__text {
    padding-top: 16px;
  }

  .circle-share__text {
    color: var(--behaviorGreen);
    cursor: pointer;
  }

  .curriculum__icon {
    background-image: url(../../../public/assets/images/theme-2/icons/curriculum.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    width: 23px;
    height: 23px;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }

  .lp--js-openAlexandriaViewerModal {
    cursor: pointer;
    outline: none;
    transition: color 0.2s ease-in-out;

    &:hover,
    &:focus {
      color: var(--white);
      transition: color 0.2s ease-in-out;

      .curriculum__icon {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;
      }
    }
  }
}

.start-panel-arrow {
  background-image: url(../../../public/assets/images/theme-2/icons/arrow.svg);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-left: 19px;
  transform: rotate(90deg);
}

.lp--shareLink {
  font-size: 15px;
  color: var(--behaviorGreenFront);
  background-image: url('../../../public/assets/images/tmp/v2/icons/shareIcon.png');
  background-repeat: no-repeat;
  background-position: center left;
  padding: 0px 0px 0px 33px;
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;

  span {
    border-bottom: 1px solid var(--transparent);
    transition: border-color 0.2s ease;
  }

  &:hover,
  &:focus {
    span {
      border-color: var(--behaviorGreen);
      transition: border-color 0.2s ease;
    }
  }
}

.pr2--circlePanelActivities {
  padding: 0px 45px 0px 45px;
  margin: 0px 0px 65px 0px;

  .pr2--circlePanelActivitiesList {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
    padding: 0px;
    font-size: 15px;
  }

  .pr2--activityItem {
    transition-property: color;
    transition-duration: 0.2s;
    padding: 0px 0px 0px 35px;
    margin: 0px 0px 20px 0px;
    color: var(--white_6);
    min-height: 21px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--white);
    }

    &::before {
      background-repeat: no-repeat;
      background-position: center center;
      width: 33px;
      height: 33px;
      content: '';
      position: absolute;
      margin: -3px 0 0 -35px;
      border-radius: 50%;
    }
  }

  .panel-navigation {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 15px auto;
    padding-left: 37px;
    min-height: 20px;
    position: relative;
    height: 26px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.hub {
      transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
      font-size: 18px;
      font-weight: bold;
      background-color: var(--standardComponentBackground);
      border: 1px solid var(--menuButtonBorder);
      border-radius: 6px;
      padding: 10px;
      margin: 0;
      min-height: 74px;
      height: 100%;
      color: var(--hoverGrey);

      &:hover,
      &:focus {
        transition:
          color 0.2s ease-in-out,
          background-color 0.2s ease-in-out;
        background-color: var(--white_1);
        color: var(--white);
      }
    }

    .hub-image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-size: 58px;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid var(--white_1);
      background-color: var(--menuButtonBorder);
      position: absolute;
      top: calc(50% - 28px);
      left: 7px;
      flex-shrink: 0;

      &.completed {
        opacity: 0.3;
      }

      &.code {
        background-image: url('../../../public/assets/images/theme-2/icons/graph/node-passwd-protected.png');
        opacity: 0.5;
      }

      &.locked {
        background-image: url('../../../public/assets/images/theme-2/icons/graph/node-locked.png');
        opacity: 0.5;
      }
    }

    .activity-list__node-header {
      margin-left: 100px;
    }
  }

  .activity-item {
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    font-size: 16px;
    background-color: var(--standardBlackBackground);
    border: 1px solid var(--menuButtonBorder);
    border-radius: 6px;
    padding: 9px 0 9px 68px;
    min-height: 56px;
    color: var(--hoverGrey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
      color: var(--white);
      background-color: var(--standardComponentBackground);
    }

    &::before {
      background-repeat: no-repeat;
      background-position: 9px center;
      width: 33px;
      height: 33px;
      content: '';
      position: absolute;
      margin: 0 0 0 -50px;
      border-radius: 50%;
      border: 2px solid var(--transparent);
      background-color: var(--black);
    }

    &.done::before,
    &.accepted::before {
      border: 2px solid var(--menuButtonBorder);
    }

    .indicators {
      display: flex;
    }

    .node-accepted,
    .notification {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-size: cover;
      margin-right: 15px;
      visibility: hidden;
    }

    .node-accepted {
      background-image: url('../../../public/assets/images/theme-2/icons/accepted.png');
    }

    .notification {
      background-color: var(--behaviorPink);
    }

    &.accepted .node-accepted,
    .notification.show {
      visibility: visible;
    }
  }

  .delimiter {
    border-left: 1px solid var(--defaultPlaceholder);
    height: 5px;
    margin-left: 36px;

    &:first-of-type {
      height: 12px;
    }

    &.hub {
      height: 16px;

      &:first-of-type {
        display: none;
      }
    }
  }

  .lp--activityItem-quiz {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/quiz.svg');
      background-size: 19px auto;
      background-position: 5px center;
      border-color: var(--behaviorPink);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/quiz.svg');
    }
  }

  .lp--activityItem-task {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/default.svg');
      background-size: 8px auto;
      background-position: 11px center;
      border-color: var(--behaviorBlue);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/default.svg');
    }
  }

  .lp--activityItem-group {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/connected.svg');
      background-size: 15px auto;
      background-position: 7px;
      border-color: var(--behaviorGreen);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/connected.svg');
    }
  }

  .lp--activityItem-evaluation {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/evaluation.svg');
      background-size: 10px auto;
      border-color: var(--behaviorPurple);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/evaluation.svg');
    }
  }

  .lp--activityItem-private_blog {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/logbook.svg');
      background-size: 13px auto;
      border-color: var(--behaviorBlue);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/logbook.svg');
    }
  }

  .lp--activityItem-written_quiz {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/written_quiz.svg');
      background-size: 9px auto;
      background-position: 10px center;
      border-color: var(--behaviorBlue);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/written_quiz.svg');
    }
  }

  .lp--activityItem-public_blog {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/noticeboard.svg');
      background-size: 14px auto;
      background-position: 7px;
      border-color: var(--behaviorGreen);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/noticeboard.svg');
    }
  }

  .lp--activityItem-legend {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/concept.svg');
      background-size: 10px auto;
      border-color: var(--behaviorYellow);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/concept.svg');
    }
  }

  .lp--activityItem-correlation {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/correlation.svg');
      background-size: 15px auto;
      background-position: 7px center;
      border-color: var(--behaviorPink);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/correlation.svg');
    }
  }

  .lp--activityItem-chunk {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/subcat.svg');
      background-size: 15px auto;
      background-position: 8px;
      border-color: var(--behaviorYellow);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/subcat.svg');
    }
  }

  .lp--activityItem-video {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/video.svg');
      background-size: 10px auto;
      background-position: 11px 7px;
      border-color: var(--behaviorYellow);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/video.svg');
    }
  }

  .lp--activityItem-link {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/link.svg');
      background-size: 15px auto;
      background-position: 7px;
      border-color: var(--behaviorYellow);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/link.svg');
    }
  }

  .lp--activityItem-checklist {
    &::before {
      background-image: url('../../../public/assets/images/theme-2/icons/checklist.svg');
      background-size: 60%;
      background-position: center;
      border-color: var(--behaviorBlue);
    }

    &.done::before,
    &.accepted::before {
      background-image: url('../../../public/assets/images/theme-2/icons/done/checklist.svg');
    }
  }

  .pr2--activityItem:last-child {
    margin-bottom: 0px;
  }

  .pr2--selectedActivity {
    color: var(--behaviorYellow);
  }

  .pr2--doneActivity {
    opacity: 0.3;
    text-decoration: line-through;
  }

  .pr2--activity1 {
    background-image: url('../../../public/assets/images/tmp/v2/icons/graph-icon1.png');
  }

  .pr2--activity2 {
    background-image: url('../../../public/assets/images/tmp/v2/icons/graph-icon2.png');
  }

  .pr2--activity3 {
    background-image: url('../../../public/assets/images/tmp/v2/icons/graph-icon3.png');
  }
}

.pr2--circlePanelMeta,
.pr2--panelSettings,
.pr2--gridSettings {
  border: 1px solid var(--white_2);
  border-radius: 10px;
  width: 338px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 25px;
  color: var(--white_7);

  .lp--shareLink {
    margin-top: 0px;
    margin-bottom: 17px;
  }

  .pr2--circlePanelTeachersList {
    list-style-type: none;
    margin: 0px 0px 17px 0px;
    padding: 0px;
  }

  .review-button {
    background: var(--black);
    margin-bottom: 10px;
  }
}

.circle-panel-activities,
.circle-panel-description,
.circle-panel-meta {
  padding: 17px;
  margin: 17px;
  width: auto;
  background-color: var(--standardComponentBackground);
  border: 1px solid var(--menuButtonBorder);
  border-radius: 6px;
  color: var(--white_7);

  &.editor {
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 25px;
    width: 338px;
  }
}

.pr2--circlePanelTeachersList {
  .pr2--circlePanelTeachersList-Item {
    display: flex;
    align-items: center;
    margin: 0px 0px 17px 0px;
    font-size: 14px;
    color: var(--white_7);

    a {
      display: flex;
    }
  }
}

.pr2--circlePanelTeachersList-Item {
  .lp--userAvatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-size: cover;
    margin: 0px 12px 0px 0px;
  }

  .pr2--circlePanelTeacherList-AskLink {
    color: var(--behaviorGreen);
    display: inline-block;
    margin: 0px 0px 0px 12px;
  }
}

.collaborator-wrapper {
  display: flex;
  align-items: center;
}

.collaborator-remove {
  margin-left: auto;
  background-image: url('../../../public/assets/images/editor/delete-icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  outline: none;
  border: none;
  background-color: var(--transparent);
  color: var(--white);
  opacity: 0.7;
  transition: opacity 0.2s ease;
  padding: 0 0 0 20px;
  height: 14px;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    transition: opacity 0.2s ease;
    opacity: 1;
  }
}

.pr2--circlePanelCurriculumLink,
.pr2--circlePanelKnowledgeLevelLink,
.lp--circlePanelCurriculumTags,
.lp--circlePanelStartSession,
.lp--circlePanelAddCollaborator,
.lp--circlePanelLibraryPublish,
.lp--circlePanelCopyCircle,
.lp--circlePanelReorderHubs {
  color: var(--white_7);
  display: block;
  position: relative;
  padding: 0px 0px 0px 33px;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: var(--white_9);
    transition: color 0.2s ease;
  }

  &:visited {
    color: var(--white_7);
  }

  &.lp--circlePaneCurriculumLinkInactive {
    text-decoration: line-through;
  }
}

.pr2--circlePanelCurriculumLink,
.lp--circlePanelCurriculumTags,
.lp--circlePanelStartSession,
.lp--circlePanelAddCollaborator,
.lp--circlePanelLibraryPublish,
.lp--circlePanelCopyCircle,
.lp--circlePanelReorderHubs {
  margin-bottom: 18px;
}

.pr2--circlePanelMeta :last-child {
  margin-bottom: 0px;
}

.pr2--circlePanelCurriculumLink::before,
.pr2--circlePanelKnowledgeLevelLink::before,
.lp--circlePanelCurriculumTags::before,
.lp--circlePanelStartSession::before,
.lp--circlePanelAddCollaborator::before,
.lp--circlePanelLibraryPublish::before,
.lp--circlePanelCopyCircle::before,
.lp--circlePanelReorderHubs::before {
  content: '';
  opacity: 0.7;
  top: 2px;
  left: 0px;
  bottom: 0;
  right: auto;
  width: 20px;
  position: absolute;
  transition: opacity 0.2s ease;
}

.lp--circlePanelLibraryPublish::before {
  top: 0px;
}

.pr2--circlePanelCurriculumLink:hover::before,
.pr2--circlePanelKnowledgeLevelLink:hover::before,
.lp--circlePanelCurriculumTags:hover::before,
.lp--circlePanelStartSession:hover::before,
.lp--circlePanelAddCollaborator:hover::before,
.lp--circlePanelLibraryPublish:hover::before,
.lp--circlePanelCopyCircle:hover::before,
.lp--circlePanelReorderHubs:hover::before {
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.pr2--circlePanelCurriculumLink:focus::before,
.pr2--circlePanelKnowledgeLevelLink:focus::before,
.lp--circlePanelCurriculumTags:focus::before,
.lp--circlePanelStartSession:focus::before,
.lp--circlePanelAddCollaborator:focus::before,
.lp--circlePanelLibraryPublish:focus::before,
.lp--circlePanelCopyCircle:focus::before,
.lp--circlePanelReorderHubs:focus::before {
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.lp--circlePanelLibraryPublish::before {
  background: url('../../../public/assets/images/theme-2/icons/library-white.svg') no-repeat;
  background-size: 20px 20px;
}

.lp--circlePanelCurriculumTags::before {
  background: url('../../../public/assets/images/tmp/v2/icons/curriculumTags.png') no-repeat;
}

.lp--circlePanelStartSession::before {
  background: url('../../../public/assets/images/tmp/v2/icons/startSession.png') no-repeat;
}

.lp--circlePanelAddCollaborator::before {
  background: url('../../../public/assets/images/theme-2/icons/collaborator.svg') no-repeat;
  background-position: left center;
}

.lp--circlePanelCopyCircle::before {
  background: url('../../../public/assets/images/theme-2/icons/copy.svg') no-repeat;
  background-size: 15px 10px;
  background-position: 5px 3px;
}

.pr2--circlePanelCurriculumLink::before {
  background: url('../../../public/assets/images/tmp/v2/curriculum.png') no-repeat;
}

.pr2--circlePanelKnowledgeLevelLink::before {
  background: url('../../../public/assets/images/tmp/v2/knowledgeLevel.png') no-repeat;
}

.lp--circlePanelReorderHubs::before {
  background: url('../../../public/assets/images/icons/reorder.svg') no-repeat;
  background-size: 26px;
  top: -5px;
  width: 26px;
}

/*    Password    */

.lp--passwordPanelInputContainer {
  display: flex;
  margin: 25px 0px;

  .lp--passwordPanelInput {
    border: none;
    flex: 1;
    outline: none;
    border-bottom: 1px solid var(--grey);
    background-color: var(--transparent);
    color: var(--grey);
    transition: 0.2s;

    &:focus {
      border-bottom: 1px solid var(--headerGrey);
    }

    &::placeholder {
      color: var(--white_6);
      font-style: italic;

      body[data-user-theme='high_contrast'] & {
        color: var(--white_7);
      }
    }
  }
}

.lp--panelLoader {
  display: block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../../../public/assets/images/loaders/loader.gif') center center no-repeat;
  background-size: 64px 64px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .pr2--panelLogoSection {
    .pr2--panelLogo {
      background-image: url('../../../public/assets/images/tmp/v2/logo@2x.png');
    }
  }

  .pr2-circlePanelHeader {
    .pr2-circlePaneTime {
      background-image: url('../../../public/assets/images/tmp/v2/timeleft@2x.png');
    }
  }

  .lp--circlePanelCurriculumTags::before {
    background: url('../../../public/assets/images/tmp/v2/icons/curriculumTags@2x.png') no-repeat;
    background-size: 14px 14px;
  }

  .lp--circlePanelStartSession::before {
    background: url('../../../public/assets/images/tmp/v2/icons/startSession@2x.png') no-repeat;
    background-size: 10px 13px;
  }
}
